





















































































































import InternalMovement from '@/domain/internal-movement/internal-movement.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ExpansionPanel from '@/shared/expansion-panel/expansion-panel.component.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import EmployeeSelect from '@/shared/employee/employee-select.vue';
import InternalMovementService from '@/domain/internal-movement/service/internal-movement.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';

@Component({
  components: {
    ExpansionPanel,
    LabelSlot,
    DatePickerField,
    EmployeeSelect,
  },
})
export default class InternalMovementDetailsComponent extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) internalMovement!: InternalMovement;

  statusLabel: any = {
    NOT_STARTED: 'Não Iniciado',
    DOING: 'Em andamento',
    CONCLUDED: 'Concluído',
    CANCELLED: 'Cancelado',
  };

  loadingStart: boolean = false;

  async start() {
    try {
      this.loadingStart = true;
      const response = await InternalMovementService.start(this.internalMovement.id);
      VbSnackbarService.showSuccess('Movimentação iniciada com sucesso');
      this.$emit('started', response);
    } catch (error) {
      VbSnackbarService.handleHttpError(error)
    } finally {
      this.loadingStart = false;
    }
  }
}
