





























































































































































































































import CorreiosAddress from '@/domain/correios-address/entity/correios-address.entity';
import CorreiosService from '@/domain/correios-address/service/correios.service';
import Country from '@/domain/countries/entity/country.entity';
import City from '@/domain/states/entity/city.entity';
import State from '@/domain/states/entity/state.entity';
import StateService from '@/domain/states/service/states.service';
import Address from '@/domain/address/address.entity'
import { Vue, Component, Prop } from 'vue-property-decorator';
import CountrySelect from '../country-select.component.vue';
import Rules from '../rules/rules';
import LabelSlot from '../slots/label-slot.component.vue';
import StringHandler from '../utils/string-handler';
import CardTitle from '../card/card-title.component.vue';

const BRAZIL_ACRONYM = 'BRA';

export interface AddressSettingsProps {
  title: string;
  showCountry: boolean;
  showPostalCode: boolean;
  showName: boolean;
  showDistrict: boolean;
  showNumber: boolean;
  showComplement: boolean;
  showState: boolean;
  showCity: boolean;
  requiredCountry: boolean;
  requiredPostalCode: boolean;
  requiredName: boolean;
  requiredDistrict: boolean;
  requiredNumber: boolean;
  requiredComplement: boolean;
  requiredState: boolean;
  requiredCity: boolean;
}

@Component({
  components: {
    CardTitle,
    CountrySelect,
    LabelSlot,
  },
})
export default class AddressComponent extends Vue {
  @Prop({
    type: String,
    default: 'Dados Residenciais',
  }) title!: string;

  @Prop() address!: Address;

  @Prop({
    type: Object,
    default: () => ({
      title: 'Endereço',
      showCountry: true,
      showPostalCode: true,
      showName: true,
      showDistrict: true,
      showNumber: true,
      showComplement: true,
      showState: true,
      showCity: true,
      requiredCountry: true,
      requiredPostalCode: true,
      requiredName: true,
      requiredDistrict: true,
      requiredNumber: true,
      requiredComplement: false,
      requiredState: true,
      requiredCity: true,
    }),
  }) settings!: AddressSettingsProps;

  locale: string = 'pt-br';

  countries: Country[] = [];

  rules: Rules = new Rules();

  correiosAddress: CorreiosAddress = new CorreiosAddress({});

  states: State[] = [];

  cities: City[] = [];

  showBrazilAdress: boolean = false;

  showForeignAdress: boolean = false;

  foreignAdressCharacterLimit: number = 100;

  loadingAddressByPostalCode: boolean = false;

  created() {
    this.getCities();
    this.findStates();
    this.checkCountrySeleted();
  }

  updateCountry(country: string | Country) {
    this.updateField('country', country);
    this.checkCountrySeleted();
    if (this.countryIsBrazil) {
      this.findStates();
    }
  }

  updateState(state: State) {
    this.updateField('state', state);
    this.getCities();
  }

  get countryIsBrazil(): boolean {
    const countryIsBrazil = this.address.country.acronym === BRAZIL_ACRONYM;
    this.$emit('countryIsBrazil', countryIsBrazil);
    return countryIsBrazil;
  }

  filterCountryById(countryId: string) {
    return this.countries.filter(it => it.id === countryId)[0];
  }

  getAddressByPostalCode() {
    this.loadingAddressByPostalCode = true;
    CorreiosService.getAddress(this.address.postalCode)
      .then((resp: any) => {
        this.correiosAddress = CorreiosAddress.of(resp)
        this.updateField('district', this.correiosAddress.bairro);
        this.updateField('address', this.correiosAddress.logradouro);
        this.findViaCepState();
      }).finally(() => this.loadingAddressByPostalCode = false);
  }

  findViaCepState() {
    const viaCepState = this.states
      .find((state: State) => state.acronym === this.correiosAddress.uf);
    if (viaCepState) {
      if (!this.address.state || this.address.state.id !== viaCepState.id) {
        this.updateField('state', viaCepState);
        this.getCities();
      }
    }
  }

  findStates() {
    if (!this.address.country.name) return;
    StateService.findStates()
      .then((resp: any) => {
        this.states = resp;
      });
  }

  getCities() {
    if (!this.address.state.name) return;
    StateService.findCities(this.address.state.id)
      .then((resp: any) => {
        this.cities = resp;
        if (this.correiosAddress.localidade) {
          const viaCepCity = this.cities
            .find((city: City) => StringHandler
              .equalsNormalized(city.name, this.correiosAddress.localidade));
          if (viaCepCity) {
            this.updateField('city', viaCepCity);
          }
        }
      });
  }

  showViewBrazilAdress() {
    this.showBrazilAdress = true;
    this.showForeignAdress = false;
  }

  showViewForeignAdress() {
    this.showBrazilAdress = false;
    this.showForeignAdress = true;
  }

  checkCountrySeleted() {
    if (this.countryIsBrazil) {
      this.updateField('foreignCity', '');
      this.updateField('foreignState', '');
      this.showViewBrazilAdress();
      return;
    }
    this.updateField('state', '');
    this.updateField('city', '');
    this.showViewForeignAdress();
  }

  updateField(field: string, value: any) {
    this.$emit('updateField', field, value);
  }

  get postalCodeLabel(): string {
    return this.countryIsBrazil
      ? `CEP ${this.settings.requiredPostalCode ? '*' : ''}`
      : `Código postal ${this.settings.requiredPostalCode ? '*' : ''}`;
  }

  get hintMessage(): string {
    if (this.countrySelected) return '';
    return 'Por favor, informe o país'
  }

  get countrySelected(): boolean {
    return this.address.country.name !== '' && this.address.country !== undefined && this.address.country !== null;
  }
}
