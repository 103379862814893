























































import { Vue, Component, Prop } from 'vue-property-decorator';
import PaginationComponent from '@/shared/pagination.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import AssessmentResult from '@/domain/assessment/entity/assessment-result.entity';
import Headers from './header.json';

@Component({
  components: {
    PaginationComponent,
  },
})
export default class AssessmentTable extends Vue {
  @Prop() items!: AssessmentResult[];

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop() page!: any;

  @Prop({
    type: Array,
    required: true,
  }) headers = Headers

  isMobile = false;

  updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  redirectEmployeeAssessmentResultScreen(item: AssessmentResult) {
    this.$router.push(`/assessment/${item.employeeId}/result`);
  }
}
