import moment from 'moment';

export default class Payslip {
  id: string;

  attachmentId: string;

  attachmentName: string;

  competence: string;

  loadingDelete: boolean;

  loadingDownload: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.attachmentId = obj.attachmentId || '';
    this.attachmentName = obj.attachmentName || '';
    this.competence = obj.competence || '';
    this.loadingDelete = obj.loadingDelete || false;
    this.loadingDownload = obj.loadingDownload || false;
  }

  get competenceFormatted() {
    moment.locale('pt-br')
    return moment(this.competence).format('MM/YYYY')
  }
}
