




























import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbTooltipSlot } from 'bag-of-holding-library';

@Component({ components: { VbTooltipSlot } })
export default class ProfileMenu extends Vue {
  @Prop() icon!: string;

  @Prop() iconClass!: string;

  @Prop({
    type: Boolean,
  }) checkAccessCreateCareerUpdate!: boolean;

  @Prop({
    type: Boolean,
  }) checkAccessReadUpdateHistory!: boolean;

  @Prop({
    type: Boolean,
  }) checkAccessCreateInternalMovement!: boolean;

  private requestMovement() {
    this.$emit('requestMovement');
  }

  private createEvent() {
    this.$emit('createEvent');
  }

  private showHistory() {
    this.$emit('showHistory');
  }
}
