





















































import { Vue, Component } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import AuthorityStore from '@/domain/security/authority.store';
import ActivityService from '@/domain/schedule/activitie/activity.service';
import Activity from '@/domain/schedule/activitie/activity.entity';
import ScheduleFilter from '@/domain/schedule/employee/schedule-filter.entity';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import AuthenticationService from '@/domain/security/authentication.service';
import ScheduleHeader from './schedule-header.component.vue';

@Component({
  components: {
    LabelSlot,
    MonthYearSelect,
    ScheduleHeader,
  },
})
export default class ScheduleFilterComponent extends Vue {
  activities: Activity[] = [];

  employees: EmployeeSummary[] = [];

  managers: EmployeeSummary[] = [];

  lastFilterManager = '';

  lastFilterEmployee = '';

  filter = new ScheduleFilter({});

  managerName: string = '';

  isFirstFetch: boolean = true;

  isOnlyManager: boolean = false;

  isManagerAuth() {
    return AuthorityStore.isOnlyManager()
      .then((response) => {
        this.isOnlyManager = response;
        this.applyInitialFilter();
      })
  }

  get isAdministrator() {
    return AuthorityStore.isAdministrator()
      .then(response => response)
  }

  searchManagerName() {
    if (this.isOnlyManager) {
      this.managerName = AuthenticationService.userLoggedIn().name;
      return this.findManagers(this.managerName);
    }
    return this.applyFilter();
  }

  async applyInitialFilter() {
    if (this.isOnlyManager) {
      this.searchManagerName();
    } else if (await this.isAdministrator) {
      this.applyFilter()
    }
  }

  created() {
    this.initActivities();
    this.isManagerAuth();
  }

  onChangeActive(active: boolean) {
    this.filter.active = active;
    this.applyFilter();
  }

  onChangeAudit(audit: boolean) {
    this.filter.audit = audit;
    this.applyFilter();
  }

  onChangeIndirectSubordinates(indirectSubordinates: boolean) {
    this.filter.indirectSubordinates = indirectSubordinates;
    this.applyFilter();
  }

  private initActivities() {
    ActivityService.getAll().then(activities => (this.activities = activities));
  }

  findEmployees(filter: string) {
    if (!filter) return;
    if (this.lastFilterEmployee === filter) return;
    this.lastFilterEmployee = filter;
    this.executeQuery(
      EmployeeService.findEmployeesByName(filter),
      filter,
      (response: EmployeeSummary[]) => (this.employees = response),
    );
  }

  findManagers(filter: string) {
    if (!filter) return;
    if (this.lastFilterManager === filter) return;
    this.lastFilterManager = filter;
    this.executeQuery(
      EmployeeService.findManagersByName(filter),
      filter,
      (response: EmployeeSummary[]) => (this.managers = response),
    );
  }

  private executeQuery(
    promisse: Promise<EmployeeSummary[]>,
    filter: string,
    callback: Function,
  ) {
    if (filter && filter.length >= 3) {
      promisse.then(response => callback(response));
    }

    if (filter && filter.length <= 2) {
      callback([]);
    }
  }

  applyFilter() {
    this.$emit('filter', this.filter);
  }
}
