














































































import { Vue, Component, Prop } from 'vue-property-decorator';
import AssessmentResult from '@/domain/assessment/entity/emp-assessment-result.entity';
import AssessmentService from '@/domain/assessment/service/assessment.service';
import DateHandler from '@/shared/utils/date-format';
import AssessmentResultDetails from '@/domain/assessment/entity/emp-assessment-result-details';
import SkillTypesJson from '@/views/drd-2.0/skill/skill-types.json';
import SkillType from '@/domain/assessment/entity/skill/entity/skill-type.enum';
import AssessmentResultCard from './assessment-result-card.component.vue';
import AssessmentResultDetailsTableComponent from './assessment-result-details-table.component.vue';

@Component({
  components: {
    AssessmentResultCard,
    AssessmentResultDetailsTableComponent,
  },
  filters: {
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class AssessmentResultItem extends Vue {
  @Prop() result!: AssessmentResult;

  @Prop({
    type: String,
    required: true,
  }) employeeId!: string;

  @Prop({
    type: String,
    required: true,
  }) employeeUsername!: string;

  @Prop({
    type: String,
    required: true,
  }) languageAcronym!: string;

  resultDetails: AssessmentResultDetails[] = [];

  selected: boolean = false;

  loadingResultDetails: boolean = false;

  skillTypeEnum = SkillType;

  skillTypes = SkillTypesJson;

  getResultDetails() {
    if (this.resultDetails.length > 0) return;
    this.loadingResultDetails = true;
    AssessmentService.getResultDetails(this.employeeId, this.result.assessmentId)
      .then((details: AssessmentResultDetails[]) => {
        this.resultDetails = details;
        this.result.detailsFetched = true;
      })
      .finally(() => this.loadingResultDetails = false);
  }

  resultSelected() {
    this.selected = !this.selected;
    this.getResultDetails();
  }

  getSkillColorByType(type: SkillType): string {
    return this.skillTypes.filter(skillType => skillType.value === type)[0].color;
  }
}
