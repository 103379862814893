import axios from 'axios';
import CareerUpdatePersist from '@/domain/employees/entity/career-update-persist.entity';
import InternalMovementFilter from '../entity/internal-movement-filter.entity';
import InternalMovementAction from '../entity/internal-movement-action.entity';
import InternalMovementChangeStatusResponse from '../entity/internal-movement-change-status-response.entity';
import InternalMovementUpdateRequest from '../entity/internal-movement-update-request.entity';
import InternalMovementUpdateResponse from '../entity/internal-movement-update-response.entity';

export default class InternalMovementService {
  public static requestNewInternalMovement(
    employeeId: string,
    targetDate: string,
    areasOfInterest: string,
    bankHoursBalance: number,
  ): Promise<void> {
    return axios.post('/api/internal-movement', {
      employeeId,
      targetDate,
      areasOfInterest,
      bankHoursBalance,
    });
  }

  public static async getAll(
    filter: InternalMovementFilter,
    page: number,
    size: number,
  ): Promise<any> {
    return axios.get('/api/internal-movement', {
      params: {
        ...filter, page: page - 1, size,
      },
    })
  }

  public static async getById(id: string): Promise<any> {
    return axios.get(`/api/internal-movement/${id}`).then(({ data }) => data);
  }

  public static async createAction(
    internalMovementId: string,
    description: string,
  ): Promise<InternalMovementAction> {
    return axios.post(`/api/internal-movement/${internalMovementId}/action`, { description })
      .then(({ data }) => data);
  }

  public static async getActions(internalMovementId: string): Promise<InternalMovementAction[]> {
    return axios.get(`/api/internal-movement/${internalMovementId}/actions`)
      .then(({ data }) => data);
  }

  public static async updateAction(
    actionId: string,
    description: string,
  ): Promise<InternalMovementAction> {
    return axios.put(`/api/internal-movement/action/${actionId}`, { description })
      .then(({ data }) => data);
  }

  public static async deleteAction(deleteActionId: string): Promise<void> {
    return axios.delete(`/api/internal-movement/action/${deleteActionId}`);
  }

  public static async start(
    internalMovementId: string,
  ): Promise<InternalMovementChangeStatusResponse> {
    return axios.put(`/api/internal-movement/${internalMovementId}/start`)
      .then(({ data }) => data);
  }

  public static async update(
    internalMovementId: string,
    updateRequest: InternalMovementUpdateRequest,
  ): Promise<InternalMovementUpdateResponse> {
    return axios.put(`/api/internal-movement/${internalMovementId}`, updateRequest)
      .then(({ data }) => data);
  }

  public static async conclude(
    internalMovementId: string,
    careerUpdateData: CareerUpdatePersist,
  ): Promise<InternalMovementChangeStatusResponse> {
    return axios.put(`/api/internal-movement/${internalMovementId}/conclude`, careerUpdateData)
      .then(({ data }) => data);
  }

  public static async cancel(internalMovementId: string, observation: string):
    Promise<InternalMovementChangeStatusResponse> {
    return axios.put(`/api/internal-movement/${internalMovementId}/cancel`, { observation })
      .then(({ data }) => data);
  }
}
