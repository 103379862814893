





































































































import _ from 'lodash';
import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import { AuthorityGroup } from '@/domain/graph/authorityGroup';
import AuthorityScreenPermission from '@/domain/security/authority-screen-permission.entity'
import AuthorityService from '@/domain/security/authority.service';
import ScreenEntity from '@/domain/security/screen.entity';
import Permission from '@/domain/security/permission.entity';
import UpdatePermission from '@/domain/security/update-permission.entity';
import AuthorityStore from '@/domain/security/authority.store';
import Authority from '@/domain/security/authority.entity';
import MembersModal from './members-modal.vue'

const PERMISSIONS_FLAT_MAPPER = (screen: ScreenEntity) => screen.permissions;

@Component({
  components: {
    MembersModal,
  },
})
export default class GroupComponent extends Vue {
  groups: string[][] = [];

  authorityName: string = this.$route.params.authorityName;

  loading: boolean = false;

  showMembersModal: boolean = false;

  modalKey: number = 0;

  renderModal: boolean = false;

  groupId: string = '';

  loadingSave: boolean = false;

  isUpdateSecurity: boolean = false;

  administratorGroups: AuthorityGroup[]= [
    {
      name: 'ADMINISTRATOR',
      groups: [['admin.rh', '4ec7cdc3-ec62-4ff1-888d-d4f542f7e08a'], ['equipe.interna.admin', 'a3af867b-4b05-45d8-88f4-7311c0225a89']],
    },
    {
      name: 'MANAGER',
      groups: [['gestores', '771c1464-f0bb-48a1-bee0-0f74d8321df2']],
    },
    {
      name: 'EMPLOYEE_ADMINISTRATOR',
      groups: [['equipe.rh', '6d58204d-4359-4ac4-a376-0973da487e07']],
    },
    {
      name: 'FACILITIES_ADMINISTRATOR',
      groups: [['Facilities', '5b2c5af9-007c-4fa8-a77f-ce1ee7f7b612']],
    },
    {
      name: 'BUSINESS_PARTNER',
      groups: [['equipe.business.partner', '154b3377-3f8c-4d91-91a5-d8dcc22b6927']],
    },
    {
      name: 'PERSONAL_DEPARTMENT',
      groups: [['equipe.dp', '14b3d7db-d768-4701-8dfd-c54214fba10d']],
    },
    {
      name: 'BUSINESS_INTELLIGENCE',
      groups: [['equipe.dados', '07c3467c-2a25-4274-843c-5c150caab416']],
    },
    {
      name: 'TECH_LEAD',
      groups: [['tech.leads', 'b4e73b22-2201-4f35-b9d8-bd2e0e850e4f']],
    },
    {
      name: 'INTERNAL_MOVEMENT_ADM',
      groups: [['movimentacao.interna.adm', 'd257ae94-8143-4705-93ee-324f985d4d2b']],
    },
  ]

  authorityScreensPermissions: AuthorityScreenPermission = new AuthorityScreenPermission();

  unchangedPermissions: Permission[] = [];

  get changes(): UpdatePermission[] {
    const permissions: Permission[] = this.authorityScreensPermissions.screens
      .flatMap(PERMISSIONS_FLAT_MAPPER);
    const changes: UpdatePermission[] = [];
    for (let i = 0; i < permissions.length; i += 1) {
      if (permissions[i].grantedAccess && !this.unchangedPermissions[i].grantedAccess) {
        changes.push(UpdatePermission.newAdd(permissions[i].name));
      }
      if (!permissions[i].grantedAccess && this.unchangedPermissions[i].grantedAccess) {
        changes.push(UpdatePermission.newRemove(permissions[i].name));
      }
    }
    return changes;
  }

  savePermissions(): void {
    this.loadingSave = true;
    AuthorityService.updatePermissions(this.authorityName, this.changes)
      .then(() => VbSnackbarService.showSuccess('Permissões salvas com sucesso.'))
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loadingSave = false);
  }

  async created() {
    this.groups = this.administratorGroups
      .filter(authority => authority.name === this.authorityName)[0].groups || [];
    this.getUpdatePermission();
    await this.createData();
  }

  getUpdatePermission(): void {
    AuthorityStore.fetchedAuthority().then((response: Authority) => {
      this.isUpdateSecurity = response.isUpdateSecurity();
    });
  }

  close() {
    this.showMembersModal = false;
    this.modalKey += 1;
  }

  openModal(groupId: string) {
    this.showMembersModal = true;
    this.renderModal = false;
    this.$nextTick(() => this.renderModal = true)
    this.groupId = groupId;
  }

  back() {
    this.$router.push('/security')
  }

  createData() {
    this.loading = true;
    AuthorityService.authotitiesPermissions(this.authorityName)
      .then((response) => {
        this.authorityScreensPermissions = new AuthorityScreenPermission(response);
        this.unchangedPermissions = _.cloneDeep(this.authorityScreensPermissions.screens).flatMap(
          PERMISSIONS_FLAT_MAPPER,
        );
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loading = false);
  }
}
