var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('expansion-panel',{staticClass:"mt-2",attrs:{"expanded":0},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('div',[_c('v-icon',{staticClass:"icon",attrs:{"color":"white"}},[_vm._v("mdi-target-account")])],1)]},proxy:true},{key:"title",fn:function(){return [_c('div',[_vm._v(" Ações tomadas ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-textarea',{attrs:{"placeholder":"Digite aqui a ação tomada...","outlined":"","dense":"","readonly":_vm.internalMovement.isNotStarted || _vm.internalMovement.isCancelled,"maxlength":1000,"counter":"1000"},model:{value:(_vm.newActionDescription),callback:function ($$v) {_vm.newActionDescription=$$v},expression:"newActionDescription"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":_vm.internalMovement.isNotStarted || _vm.internalMovement.isCancelled,"loading":_vm.loadingCreateAction},on:{"click":_vm.createAction}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)],1)],1),_vm._l((_vm.internalMovementActions),function(action){return _c('v-row',{key:action.id},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"d-flex align-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card-text',[_c('div',{staticClass:"d-flex align-center justify-space-between\n                      internal-movement-action-title"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(action.createdDateFormatted)+" - "+_vm._s(action.createdBy)+" ")]),(!action.editing && hover)?_c('div',{staticClass:"\n                          d-flex align-center justify-center mr-2 internal-movement-action-btn",on:{"mouseenter":function($event){_vm.showEditActionButtons = true},"mouseleave":function($event){_vm.showEditActionButtons = false}}},[_c('v-tooltip',{attrs:{"color":"primary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.internalMovement.isCancelled)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":"","icon":"","small":""},on:{"click":function($event){return _vm.handleEditAction(action)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"color":"error","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.internalMovement.isCancelled)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","small":"","depressed":"","icon":""},on:{"click":function($event){return _vm.handleDeleteAction(action)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1):_vm._e()]),(!action.editing)?_c('div',{staticClass:"mt-2"},[_c('p',[_vm._v(_vm._s(action.description))])]):_vm._e(),(action.editing)?_c('div',{staticClass:"mt-2"},[_c('v-textarea',{attrs:{"outlined":"","dense":"","rules":_vm.rules.empty,"maxlength":1000,"counter":"1000"},model:{value:(_vm.editActionDescription),callback:function ($$v) {_vm.editActionDescription=$$v},expression:"editActionDescription"}}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"depressed":"","loading":_vm.loadingEditAction},on:{"click":_vm.openDiscardChangesModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","depressed":"","disabled":_vm.editActionDescription === action.description,"loading":action.loadingEdit},on:{"click":function($event){return _vm.updateAction(action)}}},[_vm._v(" Salvar ")])],1)],1):_vm._e()])]}}],null,true)})],1)],1)],1)})],2)]},proxy:true}])}),_c('modal-warning',{attrs:{"value":_vm.openDeleteActionModal,"options":_vm.confirmDeleteActionModalOption},on:{"ok":_vm.deleteAction,"cancel":function($event){_vm.openDeleteActionModal = false}}}),_c('modal-warning',{attrs:{"value":_vm.discardChangesModal,"options":_vm.discardChangesModalOption},on:{"ok":_vm.cancelEditAction,"cancel":function($event){_vm.discardChangesModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }