import Employee from '@/domain/employees/entity/employee.entity';
import EmployeeService from '@/domain/employees/service/employee.service';
import AuthenticationService from '@/domain/security/authentication.service';
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';

export default class ActivityUtils {
  employee!: Employee;

  isAdministrator!: boolean;

  isBusinessPartner!: boolean;

  isLoggedUserProfile!: boolean;

  isSubordinate!: boolean;

  subordinateList!: string[];

  loggedUserEmail: string = '';

  isAppraiser: boolean = false;

  constructor(employee: Employee = Employee.of({})) {
    this.employee = employee || null;
    this.isAdministrator = false;
    this.isBusinessPartner = false;
    this.isLoggedUserProfile = false;
    this.isSubordinate = false;
  }

  async getAuthorities() {
    await AuthorityStore.fetchedAuthority().then((response: Authority) => {
      this.isAdministrator = response.isAdministrator();
      this.isBusinessPartner = response.isBusinnesPartner();
      this.isAppraiser = response.isAppraiser();
    });
    this.checkIsLoggedUserProfile();
    await this.checkIsSubordinate();
  }

  async checkUserAuthority() {
    await this.getAuthorities();
    return this.isLoggedUserProfile
    || this.isSubordinate
    || this.isAdministrator
    || this.isBusinessPartner
    || this.isAppraiser;
  }

  private checkIsLoggedUserProfile() {
    this.loggedUserEmail = AuthenticationService.userLoggedIn().userName.toLowerCase();
    this.isLoggedUserProfile = this.employee.availableEmails.some(
      (email: string) => email.toLowerCase() === this.loggedUserEmail,
    );
  }

  private async checkIsSubordinate() {
    await EmployeeService.getAllSubordinates().then((response) => {
      this.subordinateList = response;
      this.isSubordinate = this.subordinateList.includes(this.employee.id);
    });
  }
}
