






































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import Rules from '@/shared/rules/rules';
import ResignationStaff from '@/domain/resignation-staff/entity/resignation-staff.entity';
import ResignationStaffService from '@/domain/resignation-staff/service/resignation-staff.service';
import StaffList from '@/domain/resignation-staff/entity/staff-list.entity';
import CareerService from '@/domain/employees/service/career.service';
import StaffListRequest from '@/domain/resignation-staff/entity/staff-list-request.entity';
import debounce from '@/shared/utils/debounce';

enum ResignationType {
  VOLUNTARY = 'VOLUNTARY',
  INVOLUNTARY = 'INVOLUNTARY',
  OTHERS = 'OTHERS',
  FINISH_CONTRACT = 'FINISH_CONTRACT',
  INTERN_TO_HIRE = 'INTERN_TO_HIRE',
}

@Component({
  components: { ModalComponent, LabelSlot, VbTooltipSlot },
})
export default class ResignationStaffModal extends Vue {
  @Prop() show!: boolean;

  @Prop() showResignationStaffModal!: boolean;

  valid: boolean = false;

  rules: Rules = new Rules();

  role: any = '';

  loading = false;

  resignationStaffList: StaffList = StaffList.of({});

  resultCenters: UpdateAtribute[] = [];

  newResignationEmail: string = '';

  resinationTypesLabels = {
    VOLUNTARY: 'Voluntária',
    INVOLUNTARY: 'Involuntária',
    OTHERS: 'Outros',
    FINISH_CONTRACT: 'Término de contrato (Aprendiz/Estágio)',
    INTERN_TO_HIRE: 'Efetivação (Aprendiz/Estágio)',
  }

  loadingResultCenter: boolean = false;

  close() {
    this.$emit('close');
  }

  get optionsHandled(): ModalOptions {
    return {
      title: 'Configuração de E-mails para Desligamentos',
      titleSize: '25px',
      description: '',
      showOk: true,
      labelOk: 'Salvar',
      okColor: 'tabBlue',
      showCancel: !this.loading,
      labelCancel: 'Cancelar',
      width: 850,
      loadingOk: this.loading,
    };
  }

  created() {
    this.getStaff();
  }

  addStaffEmail() {
    const newStaff = new ResignationStaff({
      email: this.newResignationEmail,
      resignationTypes: [
        ResignationType.VOLUNTARY,
        ResignationType.INVOLUNTARY,
        ResignationType.OTHERS,
        ResignationType.FINISH_CONTRACT,
        ResignationType.INTERN_TO_HIRE,
      ],
    })
    this.resignationStaffList.addNewStaffEmail(newStaff);
    this.newResignationEmail = '';
    const { form }: any = this.$refs;
    form.resetValidation()
  }

  removeStaff(index: number) {
    this.resignationStaffList.staffList.splice(index, 1);
  }

  getStaff() {
    ResignationStaffService.findStaffEmails()
      .then((resp: any) => {
        this.resignationStaffList.staffList = resp;
        this.fetchPossibleResultCenters();
      });
  }

  fetchPossibleResultCenters() {
    this.resignationStaffList.staffList.forEach((el: ResignationStaff) => {
      el.resultCenters.forEach((resultCenter:UpdateAtribute) => {
        if (resultCenter.name) {
          this.getResultCentersDebouce(resultCenter.name, el);
        }
      })
    })
  }


  getResultCenters(value: string, staff: ResignationStaff) {
    if (value && value.length >= 3 && value !== staff.lastFilteredName) {
      this.loadingResultCenter = true;
      CareerService.getResultCentersByName(value)
        .then((resp: UpdateAtribute[]) => {
          staff.addResultCenters(resp)
          staff.lastFilteredName = value;
        }).finally(() => this.loadingResultCenter = false);
    }
  }

  getResultCentersDebouce(value: string, staff: ResignationStaff) {
    debounce.debounce(() => this.getResultCenters(value, staff));
  }

  checkResultCenter(staff: ResignationStaff) {
    staff.checkHasResultCenterState();
  }

  save() {
    if (!this.resignationStaffList.validateDuplicatedStaff()) {
      VbSnackbarService.showError('Existem e-mails duplicados!')
      return;
    }
    this.loading = true;
    const request = StaffListRequest.of(this.resignationStaffList);
    ResignationStaffService.saveStaff(request)
      .then((resp: any) => {
        VbSnackbarService.showSuccess('E-mails salvos com sucesso!');
        this.close();
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => this.loading = false);
  }
}
