import routeChecker from '../route-checker';
import InternalMovementView from './internal-movement.view.vue';
import InternalMovementListComponent from './internal-movement-list.component.vue';
import InternalMovementFormComponent from './update/internal-movement-form.component.vue';

export default [
  {
    path: '/internal-movement',
    name: 'Movimentações Internas',
    component: InternalMovementView,
    children: [
      {
        path: '/',
        name: 'Listagem Movimentações Internas',
        component: InternalMovementListComponent,
        beforeEnter: routeChecker.hasAccessInternalMovement,
      },
      {
        path: '/internal-movement/:id',
        name: 'Detalhes Movimentação Interna',
        component: InternalMovementFormComponent,
        beforeEnter: routeChecker.hasAccessInternalMovement,
      },
    ],
  }]
