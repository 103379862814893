export default class InternalMovementFilter {
  employeeName: string;

  businessUnitName: string;

  requestDate: string;

  startedDate: string;

  status: string;

  concludedDate: string;

  responsibleName: string;

  talentLevelName: string;

  constructor(obj: any = {}) {
    this.employeeName = obj.employeeName || '';
    this.businessUnitName = obj.businessUnitName || '';
    this.requestDate = obj.requestDate || '';
    this.startedDate = obj.startedDate || '';
    this.status = obj.status || null;
    this.concludedDate = obj.concludedDate || '';
    this.responsibleName = obj.responsibleName || '';
    this.talentLevelName = obj.talentLevelName || '';
  }
}
