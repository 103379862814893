enum AuthorityEnum {
  ADMINISTRATOR = 'ADMINISTRATOR',
  MANAGER = 'MANAGER',
  EMPLOYEE_ADMINISTRATOR = 'EMPLOYEE_ADMINISTRATOR',
  FACILITIES_ADMINISTRATOR = 'FACILITIES_ADMINISTRATOR',
  TRAINING_ADMINISTRATOR = 'TRAINING_ADMINISTRATOR',
  BUSINESS_PARTNER = 'BUSINESS_PARTNER',
  PERSONAL_DEPARTMENT = 'PERSONAL_DEPARTMENT',
  READ_RISK_ATTENDANCE = 'READ_RISK_ATTENDANCE',
  CREATE_RISK_ATTENDANCE = 'CREATE_RISK_ATTENDANCE',
  UPDATE_RISK_ATTENDANCE = 'UPDATE_RISK_ATTENDANCE',
  DELETE_RISK_ATTENDANCE = 'DELETE_RISK_ATTENDANCE',
  CREATE_DRD = 'CREATE_DRD',
  READ_WAGES = 'READ_WAGES',
  UPDATE_DRD = 'UPDATE_DRD',
  EXPORT_GODFATHER_DATA = 'EXPORT_GODFATHER_DATA',
  EXPORT_PERSONAL_DATA = 'EXPORT_PERSONAL_DATA',
  READ_ATTENDANCE = 'READ_ATTENDANCE',
  CREATE_ATTENDANCE = 'CREATE_ATTENDANCE',
  DELETE_ATTENDANCE = 'DELETE_ATTENDANCE',
  UPDATE_ATTENDANCE = 'UPDATE_ATTENDANCE',
  READ_RESIGNATION = 'READ_RESIGNATION',
  CREATE_RESIGNATION = 'CREATE_RESIGNATION',
  UPDATE_RESIGNATION = 'UPDATE_RESIGNATION',
  DELETE_RESIGNATION = 'DELETE_RESIGNATION',
  READ_PROFESSIONAL_DATA = 'READ_PROFESSIONAL_DATA',
  UPDATE_PROFESSIONAL_DATA = 'UPDATE_PROFESSIONAL_DATA',
  CREATE_OR_UPDATE_SCORE = 'CREATE_OR_UPDATE_SCORE',
  READ_SCORE = 'READ_SCORE',
  UPDATE_GODFATHER = 'UPDATE_GODFATHER',
  UPDATE_ACOMPANIED = 'UPDATE_ACOMPANIED',
  UPDATE_NEED_TRAINING = 'UPDATE_NEED_TRAINING',
  READ_ASSIGNMENTS = 'READ_ASSIGNMENTS',
  INACTIVE_ASSIGNMENTS = 'INACTIVE_ASSIGNMENTS',
  UPDATE_ASSIGNMENTS = 'UPDATE_ASSIGNMENTS',
  CREATE_ASSIGNMENTS = 'CREATE_ASSIGNMENTS',
  READ_ROLES = 'READ_ROLES',
  UPDATE_ROLES = 'UPDATE_ROLES',
  INACTIVE_ROLES = 'INACTIVE_ROLES',
  CREATE_CARRER_UPDATE = 'CREATE_CARRER_UPDATE',
  UPDATE_CARRER_UPDATE = 'UPDATE_CARRER_UPDATE',
  DELETE_CARRER_UPDATE = 'DELETE_CARRER_UPDATE',
  DELETE_PAST_CAREER_EVENT = 'DELETE_PAST_CAREER_EVENT',
  READ_CARRER_UPDATE_HISTORY = 'READ_CARRER_UPDATE_HISTORY',
  READ_SECURITY = 'READ_SECURITY',
  UPDATE_SECURITY = 'UDPATE_SECURITY',
  READ_SCHEDULE = 'READ_SCHEDULE',
  READ_ACTIVITY = 'READ_ACTIVITY',
  UPDATE_ACTIVITY = 'UPDATE_ACTIVITY',
  UPDATE_ACTIVITY_STATUS = 'UPDATE_ACTIVITY_STATUS',
  UPLOAD_FILES = 'UPLOAD_FILES',
  CREATE_SESSION = 'CREATE_SESSION',
  DELETE_ACTIVITY = 'DELETE_ACTIVITY',
  READ_ACTIVITY_HISTORY = 'READ_ACTIVITY_HISTORY',
  RESET_EMPLOYEE_SCHEDULE = 'RESET_EMPLOYEE_SCHEDULE',
  READ_TABLE_SALARY = 'READ_TABLE_SALARY',
  UPDATE_TABLE_SALARY = 'UPDATE_TABLE_SALARY',
  READ_VACATIONS = 'READ_VACATIONS',
  CREATE_VACATIONS = 'CREATE_VACATIONS',
  UPDATE_VACATIONS = 'UPDATE_VACATIONS',
  DELETE_VACATIONS = 'DELETE_VACATIONS',
  READ_VACATION_BALANCE = 'READ_VACATION_BALANCE',
  READ_TALENT_LEVEL = 'READ_TALENT_LEVEL',
  CREATE_TALENT_LEVEL = 'CREATE_TALENT_LEVEL',
  UPDATE_TALENT_LEVEL = 'UPDATE_TALENT_LEVEL',
  INACTIVE_TALENT_LEVEL = 'INACTIVE_TALENT_LEVEL',
  READ_SALARY_READJUSTMENT = 'READ_SALARY_READJUSTMENT',
  CREATE_SALARY_READJUSTMENT = 'CREATE_SALARY_READJUSTMENT',
  UPDATE_SALARY_READJUSTMENT = 'UPDATE_SALARY_READJUSTMENT',
  DELETE_SALARY_READJUSTMENT = 'DELETE_SALARY_READJUSTMENT',
  CREATE_MESSAGE_DAISY = 'CREATE_MESSAGE_DAISY',
  READ_PERSONAL_DATA = 'READ_PERSONAL_DATA',
  READ_EMPLOYEE_SCORE = 'READ_EMPLOYEE_SCORE',
  READ_EMPLOYEE_ACTIVITY = 'READ_EMPLOYEE_ACTIVITY',
  UPLOAD_PAYSLIP = 'UPLOAD_PAYSLIP',
  READ_AGREEMENT = 'READ_AGREEMENT',
  UPDATE_PAST_CAREER_EVENT = 'UPDATE_PAST_CAREER_EVENT',
  ACTIVE_OR_INACTIVE_ACTIVITY_HISTORY = 'ACTIVE_OR_INACTIVE_ACTIVITY_HISTORY',
  CREATE_INITIAL_SCORE = 'CREATE_INITIAL_SCORE',
  READ_SELECTIVE_PROCESS = 'READ_SELECTIVE_PROCESS',
  READ_SKILL_MANAGEMENT = 'READ_SKILL_MANAGEMENT',
  READ_SKILL_MAP = 'READ_SKILL_MAP',
  CREATE_SKILL = 'CREATE_SKILL',
  UPDATE_SKILL = 'UPDATE_SKILL',
  DELETE_SKILL = 'DELETE_SKILL',
  READ_SKILL_MAP_TAB = 'READ_SKILL_MAP_TAB',
  READ_CLASSIFICATION = 'READ_CLASSIFICATION',
  UPDATE_CLASSIFICATION = 'UPDATE_CLASSIFICATION',
  CREATE_PDI_PLAN = 'CREATE_PDI_PLAN',
  UPDATE_PDI_PLAN = 'UPDATE_PDI_PLAN',
  DELETE_PDI_PLAN = 'DELETE_PDI_PLAN',
  READ_PDI_TAB = 'READ_PDI_TAB',
  READ_INDICATOR_DEFAULT = 'READ_INDICATOR_DEFAULT',
  CREATE_INDICATOR_DEFAULT = 'CREATE_INDICATOR_DEFAULT',
  UPDATE_INDICATOR_DEFAULT = 'UPDATE_INDICATOR_DEFAULT',
  INACTIVE_INDICATOR_DEFAULT = 'INACTIVE_INDICATOR_DEFAULT',
  READ_ASSESSMENT_RESULT = 'READ_ASSESSMENT_RESULT',
  READ_ACTIVITY_HAPPINESS_RESPONSE = 'READ_ACTIVITY_HAPPINESS_RESPONSE',
  READ_SKILL_KEYAREA = 'READ_SKILL_KEYAREA',
  UPDATE_KEYAREA = 'UPDATE_KEYAREA',
  DELETE_KEYAREA = 'DELETE_KEYAREA',
  CREATE_KEYAREA = 'CREATE_KEYAREA',
  READ_KEYAREA = 'READ_KEYAREA',
  READ_EMPLOYEE_TALENT_LEVEL = 'READ_EMPLOYEE_TALENT_LEVEL',
  UPDATE_CONTRACT_TYPE = 'UPDATE_CONTRACT_TYPE',
  READ_SENIORITY_REMUNERATION_HISTORY = 'READ_SENIORITY_REMUNERATION_HISTORY',
  RESCHEDULE_ACTIVITY = 'RESCHEDULE_ACTIVITY',
  UPDATE_UNLOCK_BENEFIT_PERCENT = 'UPDATE_UNLOCK_BENEFIT_PERCENT',
  UPDATE_RECEIVE_COMMISSION = 'UPDATE_RECEIVE_COMMISSION',
  READ_COMMISSION = 'READ_COMMISSION',
  CREATE_COMMISSION = 'CREATE_COMMISSION',
  CONCLUDE_COMMISSION = 'CONCLUDE_COMMISSION',
  UPDATE_COMMISSION = 'UPDATE_COMMISSION',
  DELETE_COMMISSION = 'DELETE_COMMISSION',
  CREATE_INTERNAL_MOVEMENT = 'CREATE_INTERNAL_MOVEMENT',
  READ_INTERNAL_MOVEMENT = 'READ_INTERNAL_MOVEMENT',
  IMPORT_EMPLOYEE_PAYSLIP = 'IMPORT_EMPLOYEE_PAYSLIP',
  READ_EMPLOYEE_PAYSLIP = 'READ_EMPLOYEE_PAYSLIP',
  DOWNLOAD_EMPLOYEE_PAYSLIP = 'DOWNLOAD_EMPLOYEE_PAYSLIP',
  DELETE_EMPLOYEE_PAYSLIP = 'DELETE_EMPLOYEE_PAYSLIP',
  INTERNAL_MOVEMENT_RESPONSIBLE = 'INTERNAL_MOVEMENT_RESPONSIBLE',
  APPRAISER = 'APPRAISER',
}

export default AuthorityEnum;
