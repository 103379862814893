export default class InternalMovementUpdateRequest {
  bankHoursBalance: number;

  targetDate: string;

  responsibleId: string;

  areasOfInterest: string;

  isRiskAttendance: boolean;

  constructor(obj: any = {}) {
    this.bankHoursBalance = obj.bankHoursBalance || 0;
    this.targetDate = obj.targetDate || '';
    this.responsibleId = obj.responsibleId || '';
    this.areasOfInterest = obj.areasOfInterest || '';
    this.isRiskAttendance = obj.isRiskAttendance || false;
  }
}
