export default class InternalMovementRequest {
    employeeId: string;

    targetDate: string;

    areasOfInterest: string;

    bankHoursBalance: number;

    constructor(obj: any) {
      this.employeeId = obj.employeeId || '';
      this.targetDate = obj.targetDate || '';
      this.areasOfInterest = obj.areasOfInterest || '';
      this.bankHoursBalance = obj.bankHoursBalance;
    }

    public static of(obj: any): InternalMovementRequest {
      return new InternalMovementRequest(obj || {})
    }

    public static ofList(obj: any[]): InternalMovementRequest[] {
      return obj.map(this.of);
    }
}
