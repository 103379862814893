import { render, staticRenderFns } from "./create-update-modal.component.vue?vue&type=template&id=3542cde0&scoped=true&"
import script from "./create-update-modal.component.vue?vue&type=script&lang=ts&"
export * from "./create-update-modal.component.vue?vue&type=script&lang=ts&"
import style0 from "./create-update-modal.component.vue?vue&type=style&index=0&id=3542cde0&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3542cde0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VForm,VSelect,VTextField,VTextarea})
