




















import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import CardTitle from '@/shared/card/card-title.component.vue';
import InternalMovementService from '@/domain/internal-movement/service/internal-movement.service';
import InternalMovementList from '@/domain/internal-movement/entity/internal-movement-list.entity';
import InternalMovementFilter from '@/domain/internal-movement/entity/internal-movement-filter.entity';
import InternalMovementTable from './internal-movement-table.component.vue';
import InternalMovementFilterComponent from './internal-movement-filter.component.vue';

@Component({
  components: {
    CardTitle,
    InternalMovementTable,
    InternalMovementFilterComponent,
  },
})
export default class InternalMovementListComponent extends Vue {
  loadingTable: boolean = false;

  internalMovementList: InternalMovementList[] = [];

  sortBy: Array<string> = ['requestDateFormatted'];

  sortDesc: boolean[] = [true];

  editingInternalMovementId: string | null = null;

  filter: InternalMovementFilter = new InternalMovementFilter();

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  created() {
    this.getAll();
  }

  updateTable(pagination: any) {
    if (this.pagination.itemsPerPage < pagination.itemsPerPage) {
      pagination.page = 1;
    }
    this.pagination = pagination;
    this.getAll();
  }

  getAll(filter: InternalMovementFilter = this.filter) {
    this.loadingTable = true;
    this.filter = filter;
    InternalMovementService.getAll(filter, this.pagination.page, this.pagination.itemsPerPage)
      .then(({ data }) => {
        this.internalMovementList = data.content.map((it: any) => new InternalMovementList(it));
        this.pagination.total = data.totalElements || 0;
        this.pagination.pageCount = data.totalPages;
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loadingTable = false);
  }
}
