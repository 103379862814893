















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class FilterButtonComponent extends Vue {
  active: boolean = false;

  @Prop() showFilter: boolean = false;
}
