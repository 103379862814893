import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';

export default class ResignationStaff {
  id: string;

  email: string;

  resultCenters: UpdateAtribute[] = [];

  hasResultCenter: boolean = false;

  possibleResultCenters: UpdateAtribute[] = [];

  resultCenterInput = '';

  lastFilteredName: string = '';

  resignationTypes: string[];

  constructor(obj: any) {
    this.id = obj.id || '';
    this.email = obj.email || '';
    this.resultCenters = obj.resultCenters
      ? UpdateAtribute.ofList(obj.resultCenters)
      : [];

    this.hasResultCenter = obj.resultCenters && obj.resultCenters.length > 0;
    this.resignationTypes = obj.resignationTypes || [];
  }

  public static of(obj: any): ResignationStaff {
    return new ResignationStaff(obj || {})
  }

  public static ofList(obj: any[]): ResignationStaff[] {
    return obj.map(this.of);
  }

  addResultCenters(resultCenters: UpdateAtribute[]) {
    const newResultCentersId = this.possibleResultCenters.map(el => el.id);
    resultCenters.forEach((el: UpdateAtribute) => {
      if (!newResultCentersId.includes(el.id)) {
        this.possibleResultCenters.push(el)
      }
    })
  }

  checkHasResultCenterState() {
    if (!this.hasResultCenter) {
      this.resultCenters = [];
    }
  }
}
