



































































































































































































































import ModalOptions from '@/shared/modal/modal-options.entity';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import ActionPlan from '@/domain/activity/entity/action-plan.entity';
import StateItems from '@/views/activity/common/stateItems.json';
import Priority from '@/domain/activity/entity/priority.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import { PlanState } from '@/domain/pdi/entity/plan-state';
import DateFormat from '@/shared/utils/date-format';
import PlanTypes from './plan-types.json'

@Component({
  components: {
    LabelSlot,
  },
})
export default class FormPlanModalComponent extends Vue {
  @Prop({
    type: Boolean,
  }) openPlanModal!: boolean;

  @Prop() priorityItems!: Priority[];

  planTypes = PlanTypes;

  @Prop({
    type: String,
  }) loggedUserEmail!: string;

  @Prop({
    type: Boolean,
  }) isLoggedUser!: boolean;

  @Prop({
    type: String,
    default: 'Novo plano',
  }) title!: string;

  @Prop() plan!: ActionPlan;

  @Prop({
    type: Number,
  }) planIndex!: number;

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) disablePlanType!: boolean;

  get options(): ModalOptions {
    return {
      title: this.title,
      showOk: true,
      labelOk: 'Salvar',
      loadingOk: this.loading,
      width: 1200,
      showCancel: !this.loading,
      labelCancel: 'Cancelar',
    };
  }

  rules: Rules = new Rules();

  stateItems = StateItems;

  dueDateMenu: boolean = false;

  locale: string = 'pt-br'

  close() {
    this.$emit('close', this.planIndex);
  }

  get getDueDateRules() {
    return this.plan.priority.position === 0
      || this.plan.priority.position === 1 ? this.rules.empty : [];
  }

  priorityItemText(priority: any) {
    return `${priority.position} - ${priority.description}`
  }

  updateField(field: string, value: any) {
    this.$emit('updatePlanField', { field, value, index: this.planIndex })
  }

  priorityDisable(priority: any) {
    return !priority.active;
  }

  stateItemsDisabled(state: any) {
    return state.description === PlanState.DRAFT;
  }

  createPlan(confirm: boolean) {
    const { form }: any = this.$refs;
    if (!form.validate()) return;
    if (confirm) {
      this.plan.state = PlanState.OPEN;
    }
    this.$emit('createPlan', this.plan);
  }

  updatePlan() {
    const { form }: any = this.$refs;
    if (!form.validate()) return;
    this.$emit('updatePlan', this.plan)
  }

  get dueDateText() {
    return DateFormat.timestampToLocal(this.plan.dueDate);
  }
}
