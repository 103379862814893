






















































































































































import InternalMovementAction from '@/domain/internal-movement/entity/internal-movement-action.entity';
import InternalMovementService from '@/domain/internal-movement/service/internal-movement.service';
import Rules from '@/shared/rules/rules';
import { VbSnackbarService } from 'bag-of-holding-library';
import ModalOptions from '@/shared/modal/modal-options.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import LabelSlot from 'bag-of-holding-library/src/slot/label-slot.component.vue';
import ExpansionPanel from '@/shared/expansion-panel/expansion-panel.component.vue';
import InternalMovement from '@/domain/internal-movement/internal-movement.entity';

@Component({
  components: {
    ModalWarning,
    LabelSlot,
    ExpansionPanel,
  },
})
export default class InternalMovementActionListComponent extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) internalMovement!: InternalMovement;

  internalMovementId: string = this.$route.params.id;

  internalMovementActions: InternalMovementAction[] = [];

  newActionDescription: string = '';

  loadingCreateAction: boolean = false;

  showEditActionButtons: boolean = false;

  editActionDescription: string = '';

  loadingEditAction: boolean = false;

  showEditAction: boolean = false;

  deleteActionId: string = '';

  openDeleteActionModal: boolean = false;

  confirmDeleteActionModalOption: ModalOptions = {
    title: 'Excluir ação',
    description: 'Deseja realmente excluir a ação?',
    showOk: true,
    labelOk: 'Excluir',
    okColor: 'error',
    showCancel: true,
    labelCancel: 'Cancelar',
    width: 400,
  };

  discardChangesModalOption: ModalOptions = {
    title: 'Descartar alterações',
    description: 'Deseja realmente descartar as alterações?',
    showOk: true,
    labelOk: 'Descartar',
    okColor: 'error',
    showCancel: true,
    labelCancel: 'Cancelar',
    width: 400,
  };

  discardChangesModal: boolean = false;

  internalMovementActionEditing: InternalMovementAction = new InternalMovementAction();

  rules: Rules = new Rules();

  created() {
    this.getActions();
  }

  async createAction() {
    try {
      this.loadingCreateAction = true;
      const actionCreated = await InternalMovementService
        .createAction(this.internalMovementId, this.newActionDescription);
      this.internalMovementActions.unshift(new InternalMovementAction(actionCreated));
      this.newActionDescription = '';
    } catch (error) {
      VbSnackbarService.handleHttpError(error);
    } finally {
      this.loadingCreateAction = false;
    }
  }


  async getActions() {
    try {
      const response = await InternalMovementService.getActions(this.internalMovementId);
      this.internalMovementActions = response.map((it: any) => new InternalMovementAction(it));
    } catch (error) {
      VbSnackbarService.handleHttpError(error);
    }
  }

  handleEditAction(action: InternalMovementAction) {
    this.internalMovementActionEditing = action;
    this.internalMovementActionEditing.editing = true;
    this.editActionDescription = action.description;
  }

  cancelEditAction() {
    this.discardChangesModal = false;
    this.internalMovementActionEditing.editing = false;
    this.editActionDescription = '';
  }

  openDiscardChangesModal() {
    this.discardChangesModal = true;
  }

  handleDeleteAction(action: InternalMovementAction) {
    this.deleteActionId = action.id;
    this.openDeleteActionModal = true;
  }

  async deleteAction() {
    if (!this.deleteActionId) return;
    try {
      this.confirmDeleteActionModalOption.loadingOk = true;
      this.confirmDeleteActionModalOption.showCancel = false;
      await InternalMovementService.deleteAction(this.deleteActionId);
      this.internalMovementActions = this.internalMovementActions
        .filter(action => action.id !== this.deleteActionId);
      this.openDeleteActionModal = false;
    } catch (error) {
      VbSnackbarService.handleHttpError(error);
    } finally {
      this.confirmDeleteActionModalOption.loadingOk = false;
      this.confirmDeleteActionModalOption.showCancel = true;
    }
  }

  handleCloseDeleteActionModal() {
    this.openDeleteActionModal = false;
    this.deleteActionId = '';
  }

  async updateAction(action: InternalMovementAction) {
    try {
      action.loadingEdit = true;
      await InternalMovementService
        .updateAction(action.id, this.editActionDescription);
      action.description = this.editActionDescription;
      action.editing = false;
      action.loadingEdit = false;
    } catch (error) {
      VbSnackbarService.handleHttpError(error);
    } finally {
      action.loadingEdit = false;
    }
  }
}
