















































































































































































import InternalMovement from '@/domain/internal-movement/internal-movement.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ExpansionPanel from '@/shared/expansion-panel/expansion-panel.component.vue';
import LabelSlot from 'bag-of-holding-library/src/slot/label-slot.component.vue';

@Component({
  components: {
    ExpansionPanel,
    LabelSlot,
  },
})
export default class InternalMovementEmployeeDetailsComponent extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) internalMovement!: InternalMovement;

  toProfile() {
    this.$router.push({
      name: 'Colaboradores/Perfil',
      params: {
        username: this.internalMovement.employeeUsername,
      },
    });
  }

  toRemuneration() {
    this.$router.push({
      name: 'Remuneração',
      query: {
        employeeId: this.internalMovement.employeeId,
      },
    });
  }
}
