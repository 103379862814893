












































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DateHandlerService from '@/shared/utils/date-format';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';

@Component({
  components: {
    'label-slot': LabelSlot,
  },
})
export default class DatePickerField extends Vue {
  @Prop() inputLabel?: string;

  @Prop() label?: string;

  @Prop() rules?: any;

  @Prop({
    type: String,
    default: 'pt-br',
  }) locale!: string;

  @Prop({
    required: false, default: false,
  }) disabled!: boolean;

  @Prop({
    type: String,
    default: '',
  }) value!: string;

  @Prop({
    required: false,
    default: '#0099B7',
  })
  labelColor?: string;

  @Prop({
    required: false, default: '',
  })
  labelClass?: string;

  @Prop({
    required: false, default: false,
  })
  hideDetails!: boolean;

  menu: boolean = false;

  get formatDate(): string {
    return DateHandlerService.timestampToLocal(this.value);
  }

  clear() {
    this.$emit('clear', '');
  }

  get dateFormated() {
    return DateFormat.standardDate(this.value);
  }

  input(value: string) {
    this.menu = !this.menu;
    this.$emit('input', value)
  }
}

