




















































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import AssessmentFilter from '@/domain/assessment/entity/assessment-filter.entity';
import AssessmentService from '@/domain/assessment/service/assessment.service';
import AuthorityStore from '@/domain/security/authority.store';
import SkillTypesJson from '@/views/drd-2.0/skill/skill-types.json';
import SkillType from '@/domain/assessment/entity/skill/entity/skill-type.enum';
import AssessmentResult from '@/domain/assessment/entity/assessment-result.entity';
import AssessmentResultsFilterComponent from './assessment-results-filter.component.vue';
import AssessmentTable from './assessment-table.component.vue';
import Headers from './header.json';
import AssessmentResultCard from '../employee-result/assessment-result-card.component.vue';

@Component({
  components: {
    AssessmentResultsFilterComponent,
    AssessmentTable,
    AssessmentResultCard,
  },
})
export default class AssessmentResultsView extends Vue {
  filter: AssessmentFilter = new AssessmentFilter();

  sortBy: Array<string> = [];

  sortDesc: boolean[] = [false];

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  headers = Headers;

  results: AssessmentResult[] = [];

  alreadyCreateHeader: boolean = false;

  loading: boolean = false;

  isManager: boolean = false;

  isOthersGroupsNotManager: boolean = false;

  skillTypes = SkillTypesJson;

  skillTypeEnum = SkillType;

  created() {
    this.verifyAuthorities();
    this.getResults();
  }

  updatePagination(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      this.pagination.page = 1;
    }
    this.pagination = value;
    this.getResults();
  }

  sendFilter(filter: AssessmentFilter) {
    this.filter = filter;
    this.getResults();
  }

  async getResults() {
    this.loading = true;
    await AssessmentService.getResultsAndAdherence(
      this.filter,
      this.pagination.page,
      this.pagination.itemsPerPage,
    ).then((response) => {
      this.pagination.total = response.data.totalElements || 0;
      this.pagination.pageCount = response.data.totalPages;
      this.results = response.data.content;
    })
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => this.loading = false);
  }

  verifyAuthorities() {
    AuthorityStore.fetchedAuthority()
      .then((authorities) => {
        this.isManager = authorities.isManager();
        this.isOthersGroupsNotManager = authorities.isOthersGroups();
      })
  }

  getSkillColorByType(type: SkillType): string {
    return this.skillTypes.filter(skillType => skillType.value === type)[0].color;
  }

  get adherenceTotal(): number {
    if (this.results.length === 0) {
      return 0;
    }
    const sumTotal = this.results.reduce((acc, result) => acc + result.employeeAdherenceTotal, 0);
    return sumTotal / this.results.length;
  }

  get adhrenceTotalSkill(): number {
    if (this.results.length === 0) {
      return 0;
    }
    const sumTotal = this.results.reduce((acc, result) => acc + result.skillValue, 0);
    return sumTotal / this.results.length;
  }

  get adhrenceTotalKnowledge(): number {
    if (this.results.length === 0) {
      return 0;
    }
    const sumTotal = this.results.reduce((acc, result) => acc + result.knowledgeValue, 0);
    return sumTotal / this.results.length;
  }

  get adhrenceTotalBehavior(): number {
    if (this.results.length === 0) {
      return 0;
    }
    const sumTotal = this.results.reduce((acc, result) => acc + result.behaviorValue, 0);
    return sumTotal / this.results.length;
  }
}
