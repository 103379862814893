
































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ExpansionPanel from '@/shared/expansion-panel/expansion-panel.component.vue';
import InternalMovement from '@/domain/internal-movement/internal-movement.entity';

@Component({
  components: {
    LabelSlot,
    ExpansionPanel,
  },
})
export default class InternalMovementEmployeeKnowledgesComponent extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) internalMovement!: InternalMovement;
}
