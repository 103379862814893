import AssessmentResultBySkillType from './emp-assessment-result-by-skill-type.entity';

export default class EmployeeAssessmentResult {
  assessmentId: string;

  assignmentName: string;

  level: string;

  responseDate: string;

  total: number;

  skillValue: string;

  knowledgeValue: string;

  behaviorValue: string;

  detailsFetched: boolean = false;

  simulation: boolean;

  isCurrent: boolean;

  constructor(obj: any = {}) {
    this.assessmentId = obj.assessmentId || '';
    this.responseDate = obj.responseDate || '';
    this.total = obj.total;
    this.skillValue = obj.skillValue;
    this.knowledgeValue = obj.knowledgeValue;
    this.behaviorValue = obj.behaviorValue;
    this.assignmentName = obj.assignmentName || [];
    this.level = obj.level || [];
    this.simulation = obj.simulation;
    this.isCurrent = obj.isCurrent;
  }
}
