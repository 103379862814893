import axios from 'axios';

export default class PayslipService {
  public static linkEmployee(employeeId: string, payslipId: string): Promise<void> {
    return axios.patch(`/api/employee-payslip/${employeeId}/${payslipId}/link`);
  }

  public static uploadPayslip(competence: string, payslip: any): Promise<void> {
    const formData = new FormData();
    formData.append('file', payslip);
    return axios.post(`/api/employee-payslip/${competence}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: Infinity,
    })
  }

  public static findByEmployeeId(
    employeeId: string,
    pagination: any,
  ): Promise<any> {
    const params = {
      page: pagination.page,
      size: pagination.size,
      sort: pagination.sort,
    };
    return axios.get(`/api/employee-payslip/${employeeId}`, { params });
  }

  public static download(payslipId: string): Promise<Blob> {
    return axios.get(`/api/employee-payslip/${payslipId}/download`, {
      responseType: 'blob',
    })
      .then(({ data }) => data);
  }

  public static delete(payslipId: string): Promise<void> {
    return axios.delete(`/api/employee-payslip/${payslipId}`);
  }
}
