































































import CommissionFilter from '@/domain/commission/entity/commission-filter.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import FilterButtonComponent from '@/shared/buttons/filter-button.component.vue';
import debounce from '@/shared/utils/debounce';
import MonthYearMenuSelect from '@/shared/month-year-picker/month-year-menu-select.component.vue';

@Component({
  components: {
    LabelSlot,
    MonthYearSelect,
    MonthYearMenuSelect,
    FilterButtonComponent,
  },
})
export default class CommissionFilterComponent extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) isOnlyManager!: boolean;

  filter: CommissionFilter = new CommissionFilter()

  showFilter: boolean = false;

  statusOptions = [
    {
      text: 'Todos',
      value: null,
    },
    {
      text: 'Concluídos',
      value: true,
    },
    {
      text: 'Não concluídos',
      value: false,
    },
  ]

  clearEmployeeName() {
    this.filter.employeeName = '';
    this.find()
  }

  clearCreatedBy() {
    this.filter.createdByName = '';
    this.find()
  }

  find() {
    this.$emit('find', this.filter);
  }

  findDebouce(value: string) {
    if (value.length !== 0 && value.length < 3) return;
    debounce.debounce(() => this.find())
  }
}
