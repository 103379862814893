





























import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import CareerService from '@/domain/employees/service/career.service';
import CareerUpdateHistory from '../../../../domain/employees/entity/career-update-history.entity';
import HistoryTable from './history-modal-table.component.vue';
import MobileHandler from '../../../../shared/utils/mobile-handler';

@Component({ components: { HistoryTable } })
export default class CreateUpdateModal extends Vue {
  @Prop() showModal!: boolean;

  @Prop() employeeId!: string;

  private careerUpdates: CareerUpdateHistory[] = [];

  private onResize() {
    if (MobileHandler.isMobile()) {
      this.close();
    }
  }

  private created() {
    this.getCareerHistory();
  }

  @Watch('showModal')
  private getCareerHistory() {
    if (this.employeeId && this.showModal) {
      CareerService.getCareerUpdateHistory(this.employeeId)
        .then((response: any) => {
          this.careerUpdates = response;
        })
        .catch((err: any) => VbSnackbarService.handleHttpError(err));
    }
  }

  private close() {
    this.$emit('close');
  }
}
