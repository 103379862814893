














































import { Vue, Component, Prop } from 'vue-property-decorator';
import MobileHandler from '@/shared/utils/mobile-handler';
import PaginationComponent from '@/shared/pagination.component.vue';
import InternalMovementList from '@/domain/internal-movement/entity/internal-movement-list.entity';

@Component({
  components: {
    PaginationComponent,
  },
})
export default class InternalMovementTable extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  }) items!: InternalMovementList[];

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop() page!: any;

  headers = [
    {
      text: 'Colaborador',
      value: 'employeeName',
    },
    {
      text: 'Unidade de Negócio',
      value: 'businessUnitName',
    },
    {
      text: 'Data da Solicitação',
      value: 'requestDateFormatted',
    },
    {
      text: 'Data do Atendimento',
      value: 'startedDateFormatted',
    },
    {
      text: 'Status',
      value: 'status',
    },
    {
      text: 'Data de Conclusão',
      value: 'concludedDateFormatted',
    },
    {
      text: 'Stage',
      value: 'talentLevelName',
    },
    {
      text: 'Ações',
      value: 'action',
      align: 'center',
    },
  ]

  statusColor = {
    CONCLUDED: 'success--text',
    DOING: 'info--text',
    NOT_STARTED: 'warning--text',
    TALENT_BANK: 'purple--text',
    CANCELLED: 'error--text',
  }

  movementStatus = {
    CONCLUDED: 'Concluído',
    DOING: 'Em Andamento',
    NOT_STARTED: 'Não Iniciado',
    TALENT_BANK: 'Banco de Talentos',
    CANCELLED: 'Cancelado',
  }

  isMobile = false;

  onResize() {
    this.isMobile = MobileHandler.isLessThan(600);
  }

  updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  editItem(id: string): void {
    this.$router.push({
      name: 'Detalhes Movimentação Interna',
      params: {
        id,
      },
    });
  }
}
