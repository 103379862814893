import ResignationStaff from './resignation-staff.entity';

export default class StaffList {
  staffList: ResignationStaff[];

  constructor(obj: any) {
    this.staffList = obj.staffList || [];
  }

  public static of(obj: any): StaffList {
    return new StaffList(obj || {})
  }

  public static ofList(obj: any[]): StaffList[] {
    return obj.map(this.of);
  }

  addNewStaffEmail(resignationStaff: ResignationStaff): void {
    this.staffList.push(resignationStaff);
  }

  validateDuplicatedStaff(): boolean {
    let valid: boolean = true;
    const existentStaff: string[] = [];
    this.staffList.forEach((el: ResignationStaff) => {
      if (!existentStaff.includes(el.email)) {
        existentStaff.push(el.email);
      } else {
        valid = false;
      }
    })
    return valid;
  }
}
