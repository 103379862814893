import moment from 'moment';
import Address from '@/domain/address/address.entity';
import EmployeeAttendanceDTO from './employee-attendance-dto';

export default class Attendance {
  id: string;

  employee: EmployeeAttendanceDTO;

  responsible: EmployeeAttendanceDTO;

  report: string;

  attendanceDate: string;

  attendanceType: string;

  status: string;

  requester: string;

  requestReason: string;

  expectedDate: string;

  actionPlan: string;

  actionPlanStatus: string;

  mappedRisk: string;

  observation: string;

  critical: boolean = false;

  resignationRisk: boolean = true;

  improveSuggestion: string;

  mainResignationReason: string;

  secondResignationReason: string;

  newCompany: string;

  currentRemuneration: string;

  proposal: string;

  admCompetence: boolean;

  lastFollowUp: string;

  mouthsWithoutFollowUp: string;

  changeTeam: boolean;

  countDate: string;

  stateNewCompany: string = '';

  cityNewCompany: string | null = '';

  stateWhereWillLive: string = '';

  cityWhereWillLive: string | null = '';

  beforeMapped: boolean;

  newCompanyAddress: Address | null;

  willLiveAddress: Address | null;

  constructor(attendance: any) {
    this.id = attendance.id || '';
    this.employee = this.createEmployee(attendance.employee);
    this.responsible = this.createEmployee(attendance.responsible);
    this.report = attendance.report || null;
    this.attendanceDate = attendance.attendanceDate || this.dateAttendanceSuggestion()
    this.attendanceType = attendance.attendanceType || null;
    this.status = attendance.status || null;
    this.requester = attendance.requester || '';
    this.requestReason = attendance.requestReason || 'SYSTEM';
    this.expectedDate = attendance.expectedDate || '';
    this.actionPlan = attendance.actionPlan || null;
    this.actionPlanStatus = attendance.actionPlanStatus || null;
    this.mappedRisk = attendance.mappedRisk || null;
    this.observation = attendance.observation || null;
    this.critical = this.updateCritical(attendance.critical);
    this.resignationRisk = this.updateRisk(attendance.resignationRisk);
    this.improveSuggestion = attendance.improveSuggestion || null;
    this.mainResignationReason = attendance.mainResignationReason || null;
    this.secondResignationReason = attendance.secondResignationReason || null;
    this.newCompany = attendance.newCompany || null;
    this.cityNewCompany = attendance.cityNewCompany || null;
    this.cityWhereWillLive = attendance.cityWhereWillLive || null;
    this.currentRemuneration = attendance.currentRemuneration || null;
    this.proposal = attendance.proposal || null;
    this.admCompetence = attendance.admCompetence || false;
    this.lastFollowUp = attendance.lastFollowUp || '';
    this.mouthsWithoutFollowUp = attendance.mouthsWithoutFollowUp || '0';
    this.changeTeam = attendance.changeTeam || false;
    this.countDate = attendance.countDate || '';
    this.beforeMapped = attendance.beforeMapped || false;
    this.stateNewCompany = attendance.stateNewCompany;
    this.stateWhereWillLive = attendance.stateWhereWillLive;
    this.newCompanyAddress = this.createAddress(attendance.newCompanyAddress);
    this.willLiveAddress = this.createAddress(attendance.willLiveAddress);
  }

  createEmployee(employee): EmployeeAttendanceDTO {
    if (employee === null) return EmployeeAttendanceDTO.of({});
    return EmployeeAttendanceDTO.of(employee);
  }

  createAddress(address): Address | null {
    if (address === null) return null;
    return Address.of(address);
  }

  public static of(attendance: any): Attendance {
    return new Attendance(attendance);
  }

  dateAttendanceSuggestion() {
    return moment().format('YYYY-MM-DD')
  }

  getTypeDescription(): string {
    const types = [
      {
        value: 'ADAPTS',
        description: 'Adapta',
      },
      {
        value: 'FOCUS',
        description: 'Enfoque',
      },
      {
        value: 'RESIGNATION_INTERVIEW',
        description: 'Desligamento',
      },
      {
        value: 'NEW_ATTENDANCE',
        description: 'Acompanhamento',
      },
      {
        value: 'ADAPTS_CHANGE',
        description: 'Adapta Mudança',
      },
    ]
    return types.filter(collection => collection.value === this.attendanceType)
      .map(it => it.description).toString()
  }

  equals(attendance: Attendance): boolean {
    return (
      this.id === attendance.id
        && this.employee === attendance.employee
        && this.responsible === attendance.responsible
        && this.report === attendance.report
        && this.attendanceDate === attendance.attendanceDate
        && this.attendanceType === attendance.attendanceType
        && this.status === attendance.status
        && this.requester === attendance.requester
        && this.requestReason === attendance.requestReason
        && this.expectedDate === attendance.expectedDate
        && this.actionPlan === attendance.actionPlan
        && this.actionPlanStatus === attendance.actionPlanStatus
        && this.mappedRisk === attendance.mappedRisk
        && this.observation === attendance.observation
        && this.critical === attendance.critical
        && this.resignationRisk === attendance.resignationRisk
        && this.improveSuggestion === attendance.improveSuggestion
        && this.mainResignationReason === attendance.mainResignationReason
        && this.secondResignationReason === attendance.secondResignationReason
        && this.newCompany === attendance.newCompany
        && this.cityNewCompany === attendance.cityNewCompany
        && this.cityWhereWillLive === attendance.cityWhereWillLive
        && this.newCompanyAddress === attendance.newCompanyAddress
        && this.willLiveAddress === attendance.willLiveAddress
        && this.currentRemuneration === attendance.currentRemuneration
        && this.proposal === attendance.proposal
        && this.admCompetence === attendance.admCompetence
        && this.lastFollowUp === attendance.lastFollowUp
        && this.mouthsWithoutFollowUp === attendance.mouthsWithoutFollowUp
        && this.changeTeam === attendance.changeTeam
        && this.countDate === attendance.countDate
        && this.beforeMapped === attendance.beforeMapped
    );
  }

  public static formatValue(value: string): string {
    const excludedCharacters = ['¥', 'R', '$', '%', '.', ' '];

    return value.split('')

      .filter(el => !excludedCharacters.includes(el))

      .join('')

      .replace(',', '.');
  }

  private updateCritical(critical) {
    if (critical === null) { return false; }
    return critical;
  }

  private updateRisk(risk) {
    if (risk === null) { return false }
    return risk;
  }

  isOpen() {
    return this.status === 'NEXT_ATTENDANCE' || this.status === 'LATE_ATTENDANCE' || (this.isClose() && this.responsible.name === '')
  }

  isClose() {
    return this.status === 'RESOLVED_ATTENDANCE' || this.status === 'RESOLVED_WITH_DELAY'
  }

  get isRegularAttendance(): Boolean {
    const description = this.getTypeDescription();
    return description !== 'Desligamento';
  }

  get isFollowUpInterview(): Boolean {
    const description = this.getTypeDescription();
    return description === 'Acompanhamento';
  }
}
