var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[(_vm.checkAccessPayslip)?_c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"sort-by":_vm.sortBy,"loading":_vm.loadingListPayslip,"loading-text":"Carregando holerites...","sort-desc":"","no-data-text":"Nenhum holerite encontrado...","hide-default-footer":"","hide-default-header":_vm.isMobile},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',[_c('modal-warning',{attrs:{"options":_vm.deleteModalWarningOptions,"value":_vm.openDeleteModalWarning},on:{"ok":_vm.deleteItem,"cancel":_vm.closeDeleteModalWarning}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.hasAccessDownloadPayslip || _vm.isLoggedUser)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","loading":item.loadingDownload},on:{"click":function($event){return _vm.download(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Baixar")])])],1):_vm._e(),(_vm.hasAccessDeletePayslip)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error","loading":item.loadingDelete},on:{"click":function($event){return _vm.handleOpenDeleteModalWarning(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1):_vm._e()],1)]}}],null,true)}):_vm._e()],1)],1),(!_vm.checkAccessPayslip)?_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"no-access"},[_vm._v(" Acesso não autorizado ")])])],1):_vm._e(),(_vm.items.length > 0 && _vm.items.length < _vm.tableSize)?_c('v-btn',{staticClass:"mt-3 justify-content-center",attrs:{"color":"primary","loading":_vm.loadingListPayslip,"small":""},on:{"click":_vm.handleItemsPerPage}},[_vm._v(" "+_vm._s('Ver Mais')+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }