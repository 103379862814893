













































import {
  Vue, Prop, Component,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ReadOnlyTextField from '@/shared/fields/readonly-text-field.component.vue';
import EmployeeDateActivities from '@/domain/schedule/employee/employee-date-activity.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import ActivityDetailed from '@/domain/schedule/activitie/activity-detailed.entity';
import Loading from '@/shared/loading/loading.component.vue';
import NotFoundComponent from '@/shared/not-found/not-found.component.vue';
import ManageActivityList from './manage-activity-list.component.vue';

@Component({
  components: {
    'text-field': ReadOnlyTextField,
    'activity-list': ManageActivityList,
    Loading,
    NotFoundComponent,
  },
})
export default class ManageActivity extends Vue {
  @Prop({
    required: true,
  })
  private employeeId!: string;

  @Prop({
    required: true,
  })
  private username!: string;

  @Prop({
    required: true,
  })
  private date!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUploadFiles!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isDeleteActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivityStatus!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isReadActivity!: boolean;

  @Prop({ type: Boolean, default: false })
  isReadEmployeeTalentLevel!: boolean;

  private employeeDateActivities: EmployeeDateActivities = new EmployeeDateActivities();

  private listKey: number = 0;

  loading: boolean = false;

  private get listOfActivities(): ActivityDetailed[] {
    return this.employeeDateActivities.listOfActivities;
  }

  private get formattedReferenceDate(): string {
    return this.employeeDateActivities.formattedDate;
  }

  private get firstNameLastName(): string {
    return this.employeeDateActivities.firstNameLastName;
  }

  private fetchEmployeeDateActivities(): void {
    this.loading = true;
    EmployeeService.getEmployeeActivitiesByDate(this.employeeId, this.date)
      .then((response: any) => this.employeeDateActivities = response)
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => this.loading = false);
  }

  private updateList(): void {
    this.fetchEmployeeDateActivities();
    this.listKey += 1;
  }

  created() {
    this.fetchEmployeeDateActivities();
  }

  refresh() {
    this.fetchEmployeeDateActivities();
  }
}
