




































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import ModalConfirmMixin from '@/shared/modal/modal-confirm.mixin';
import LabelSlot from '../slots/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class ModalWarning extends Mixins(ModalConfirmMixin) {
  @Prop({ type: Boolean, default: false }) showTextArea!: boolean;

  @Prop({ type: String, default: '' }) textAreaLabel!: string;

  @Prop({ type: String, default: null }) textAreaMaxLength!: string | null;

  textAreaValue: string = '';

  updateTextArea(value: string) {
    this.textAreaValue = value;
    this.$emit('input', value);
  }
}
