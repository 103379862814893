















import { Vue, Component } from 'vue-property-decorator';
import AuthorityStore from '@/domain/security/authority.store';
import Authority from '@/domain/security/authority.entity';
import AuthorityEnum from '@/domain/security/authority.enum';
import CardTitle from '@/shared/card/card-title.component.vue';
import VacationComponent from './vacation-payslip.component.vue'
import PayslipComponent from './payslip.component.vue'

@Component({
  components: {
    VacationComponent,
    PayslipComponent,
    CardTitle,
  },
})
export default class PayslipView extends Vue {
  hasAccessImportVacationPayslip: boolean = false;

  hasAccessImportEmployeePayslip: boolean = false;

  created() {
    this.validateAuthoryties();
  }

  validateAuthoryties() {
    AuthorityStore.fetchedAuthority().then((authority: Authority) => {
      this.hasAccessImportVacationPayslip = authority
        .hasAuthorities([AuthorityEnum.UPLOAD_PAYSLIP]);
      this.hasAccessImportEmployeePayslip = authority
        .hasAuthorities([AuthorityEnum.IMPORT_EMPLOYEE_PAYSLIP]);
    })
  }
}
