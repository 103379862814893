





















import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import AttendanceService from '@/domain/attendance/service/attendance.service';
import ManagerService from '@/domain/employees/service/employee.service';
import Attendance from '@/domain/attendance/entity/attendance.entity';
import EmployeeSummary from '../../../domain/schedule/employee/employee-summary.entity';
import Filter from '../../../domain/attendance/entity/filter.entity';
import UpdateAtribute from '../../../domain/employees/entity/update-atribute.entity';
import HeaderComponent from './components/form-header.component.vue';
import DefaultComponent from './components/default-component.vue';

@Component({
  components: {
    CardTitle,
    HeaderComponent,
    DefaultComponent,
  },
})
export default class Attendances extends Vue {
  attendance: Attendance = Attendance.of({});

  filterEmployees: EmployeeSummary[] = [];

  businessUnit: UpdateAtribute[] = [];

  lastFilterManager: string = '';

  lastFilterEmployee: string = '';

  tableLoading: boolean = false;

  isRegularAttendance: boolean = true;

  isFollowUpInterview: boolean = false;

  lastFilter: Filter = new Filter({});

  filter: Filter = Filter.of({});

  managers: EmployeeSummary[] = [];

  typeDescription: string = ''

  status: string = '';

  created() {
    this.getDate();
  }

  getDate() {
    if (this.$route.params.id) {
      AttendanceService.getAttendance(this.$route.params.id)
        .then((response: Attendance) => {
          this.attendance = response;
        });
    }
  }

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        ManagerService.findManagersByName(value)
          .then((resp: EmployeeSummary[]) => this.managers = resp);
      }
    }
  }
}

