
























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import MobileHandler from '@/shared/utils/mobile-handler';
import PayslipService from '@/domain/employees/service/payslip.service';
import Payslip from '@/domain/employees/entity/payslip.entity';
import ModalOptions from '@/shared/modal/modal-options.entity';
import AuthorityStore from '@/domain/security/authority.store';
import Authority from '@/domain/security/authority.entity';
import AuthorityEnum from '@/domain/security/authority.enum';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';

@Component({
  components: {
    VbTooltipSlot,
    ModalWarning,
  },
})
export default class EmployeePayslipComponent extends Vue {
  @Prop({
    type: String,
    default: false,
  }) employeeId!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) checkAccessPayslip!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) isLoggedUser!: boolean;

  isMobile: boolean = false;

  tableSize: number = 0;

  pagination: any = {
    page: 0,
    size: 3,
    sort: 'competence,desc',
  };

  items: Payslip[] = [];

  sortBy: Array<string> = ['competence'];

  loadingListPayslip: boolean = false;

  loadingDelete: boolean = false;

  openDeleteModalWarning: boolean = false;

  itemDeleted!: Payslip;

  headers = [
    {
      text: 'Competência',
      value: 'competenceFormatted',
    },
    {
      text: 'Ações',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  deleteModalWarningOptions: ModalOptions = {
    title: 'Atenção',
    description: 'Deseja realmente prosseguir? Ao clicar no botão Sim, as alterações serão imediatamente realizadas.',
    loadingOk: this.loadingDelete,
    showCancel: !this.loadingDelete,
    showOk: true,
    labelOk: 'Sim',
    labelCancel: 'Não',
    width: 500,
  }

  hasAccessDownloadPayslip: boolean = false;

  hasAccessDeletePayslip: boolean = false;

  created() {
    this.validateAuthoryties()
    if (this.checkAccessPayslip) {
      this.getAll();
    }
  }

  async getAll(): Promise<void> {
    this.loadingListPayslip = true;
    await PayslipService.findByEmployeeId(this.employeeId, this.pagination)
      .then(({ data }: any) => {
        this.items.push(...data.content.map(it => new Payslip(it)));
        this.tableSize = data.totalElements;
      }).finally(() => {
        this.loadingListPayslip = false;
      })
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  handleItemsPerPage() {
    this.pagination.page += 1;
    this.getAll();
  }

  formatMonth(competence: string): string {
    return DateFormat.monthYearToFormated(competence);
  }

  async download(item: Payslip) {
    try {
      item.loadingDownload = true;
      const file = await PayslipService.download(item.id);
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = item.attachmentName;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      VbSnackbarService.handleHttpError(error);
    }
    item.loadingDownload = false;
  }

  closeDeleteModalWarning() {
    this.openDeleteModalWarning = false;
  }

  handleOpenDeleteModalWarning(item: Payslip) {
    this.itemDeleted = item;
    this.openDeleteModalWarning = true;
  }

  async deleteItem() {
    this.itemDeleted.loadingDelete = true;
    this.closeDeleteModalWarning();
    await PayslipService.delete(this.itemDeleted.id)
      .then(() => {
        this.pagination.size = this.items.length;
        this.pagination.page = 0;
        this.reloadCurrentPage();
        this.pagination.page = (this.items.length / 3) - 1;
        this.pagination.size = 3;
        VbSnackbarService.showSuccess('Holerite deletado com sucesso!');
      })
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.itemDeleted.loadingDelete = false;
      })
  }

  async reloadCurrentPage() {
    await PayslipService.findByEmployeeId(this.employeeId, this.pagination)
      .then(({ data }: any) => {
        this.items = [...data.content.map(it => new Payslip(it))];
        this.tableSize = data.totalElements;
      })
  }

  validateAuthoryties() {
    AuthorityStore.fetchedAuthority().then((authority: Authority) => {
      this.hasAccessDownloadPayslip = authority
        .hasAuthorities([AuthorityEnum.DOWNLOAD_EMPLOYEE_PAYSLIP]);
      this.hasAccessDeletePayslip = authority
        .hasAuthorities([AuthorityEnum.DELETE_EMPLOYEE_PAYSLIP]);
    })
  }
}
