





import { Vue, Component } from 'vue-property-decorator';

@Component({ components: { } })
export default class SeniorityRemuneration extends Vue {
  routerKey: number = 0;

  onRouterViewChange(to: any, from: any) {
    if (to.path !== from.path) {
      this.routerKey += 1;
    }
  }

  created() {
    this.$watch('$route', this.onRouterViewChange);
  }
}
