import DateFormat from '@/shared/utils/date-format';

export default class InternalMovementAction {
  id: string

  description: string;

  createdBy: string;

  createdDate: string;

  lastModifiedBy: string;

  lastModifiedDate: string;

  editing = false;

  loadingEdit: boolean = false;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.description = obj.description || '';
    this.createdBy = obj.createdBy || '';
    this.createdDate = obj.createdDate || '';
    this.lastModifiedBy = obj.lastModifiedBy || '';
    this.lastModifiedDate = obj.lastModifiedDate || '';
  }

  get createdDateFormatted() {
    return this.createdDate ? DateFormat.dateTimeFormated(this.createdDate) : '';
  }
}
