










































































































































































import moment from 'moment';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import DateFormat from '@/shared/utils/date-format';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import Score from '@/domain/employees/entity/score.entity';
import ScoreService from '@/domain/employees/service/score.service';
import Rules from '@/shared/rules/rules';
import EmployeeService from '@/domain/employees/service/employee.service';
import { VbSnackbarService, VbLoadingService } from 'bag-of-holding-library';
import Agreement from '@/domain/employees/entity/agreement.entity';
import ScoreMessage from '@/views/score/score-message.component.vue';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import CareerService from '@/domain/employees/service/career.service';
import TooltipIcon from '@/shared/tooltip-icon.component.vue';
import ScoreDependentBenefits from '@/domain/employees/entity/score-dependent-benefits';
import ScoreScheduleService from '@/domain/schedule/score/score-schedule.service';
import HealthPlanService from '@/domain/health-plan/service/health-plan.service';
import HealthPlanDTO from '@/domain/health-plan/entity/health-plan-DTO';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ScoreDescriptionRead from '@/views/score/read/score-description-read.component.vue';
import CareerUpdate from '@/domain/employees/entity/career-update.entity';
import ActivityHappiness from '@/domain/happiness-level/entity/activity-happiness.entity';
import DrdService from '@/domain/drd/service/drd.service';
import Assignment from '@/domain/drd/entity/assignment.entity';
import { Currency } from '@/domain/employees/entity/professional-data.entity';
import ContractTypes from '@/shared/contract-type/contract-type.json';
import AgreementComponent from './agreement.component.vue';
import DependentBenefitsListComponent from './dependent-list.component.vue';
import { FixedAgreement, scoreSource, maxDayCreateScoreInMonthNow } from './score-utils';
import CreateCareerUpdateModal from '../../modal/create-update-modal.component.vue';
import ScoreModalWarning from './score-modal-warning.component.vue';
import ScoreDateComponent from './score-date.component.vue';
import ScoreBenefitsEmployeComponent from './score-benefits-employee.component.vue';
import { ContractType } from './contract-type.type';

export const fixedAgreement = [
  FixedAgreement.ASSIGNMENT.toString(),
  FixedAgreement.ROLE.toString(),
  FixedAgreement.SENIORITY.toString(),
  FixedAgreement.SALARY_IN_CLT.toString(),
  FixedAgreement.DUCZ.toString(),
  FixedAgreement.DUCZ_SYNDICATE_FREE.toString(),
  FixedAgreement.EDUCATION_SUBSIDY_ASSISTANCE.toString(),
];

@Component({
  components: {
    DatePickerField,
    LabelSlot,
    MonthYearSelect,
    CardTitle,
    AgreementComponent,
    TooltipIcon,
    DependentBenefitsListComponent,
    ScoreMessage,
    ModalWarning,
    ScoreDescriptionRead,
    CreateCareerUpdateModal,
    ScoreModalWarning,
    ScoreDateComponent,
    ScoreBenefitsEmployeComponent,
  },
  filters: {
    formatDate(value: string) {
      return DateFormat.timestampToLocal(value.split('T')[0]);
    },
  },
})

export default class ScoreComponent extends Vue {
  @Prop({
    required: true,
  })
  employeeId!: string;

  @Prop({
    required: false,
    default: null,
  })
  activityId?: string;

  @Prop({
    required: true,
    default: '',
  })
  comesFrom!: string;

  @Prop() hasCreateOrUpdate!: boolean;

  score: Score = Score.of({});

  roles: UpdateAtribute[] = [];

  rules: Rules = new Rules();

  valid: Boolean = false;

  refs: any = this.$refs

  healthPLanList:HealthPlanDTO[] = [];

  readonlyMode: boolean = false;

  showModalWarning: boolean = false;

  totalAgreement?: Agreement;

  currentCareerUpdate: CareerUpdate = new CareerUpdate({});

  showCareerUpdateModal: boolean = false;

  seniorities: any = {};

  saveCarrerUpdate: boolean = false;

  loadingSaveCarrerUpdateButton: boolean = false;

  request = {
    seniorities: [
      {
        value: 'Estagiário',
        disabled: true,
      },
      {
        value: 'Trainee',
        disabled: false,
      },
      {
        value: 'Junior',
        disabled: true,
      },
      {
        value: 'Pleno',
        disabled: false,
      },
      {
        value: 'Sênior',
        disabled: false,
      },
    ],
  }

  activity: ActivityHappiness = new ActivityHappiness();

  loading: boolean = false;

  editPrevious: boolean = false;

  dateFullFormat: string = 'YYYY-MM-DD';

  dateMothYearFormat: string = 'MM/YYYY';

  modalWarningMessage: string = 'Ao salvar o acordo, ele não poderá mais ser editado. Deseja finalizar? A senioridade e cargos não foram alterados, após finalizar, por favor verificar os dados de carrerira do colaborador.';

  loadingSaveButton: boolean = false;

  aware: boolean = false;

  enableNewUncheduleScoreButton: boolean = false;

  scoreInitialMessage: string = 'Nenhum acordo realizado para o colaborador'

  enableScoreDetailsMessage: boolean = false;

  enableScoreInicialData: boolean = false;

  enableScoreForm: boolean = false;

  scoreSource = scoreSource;

  maxDayCreateScoreInMonthNow: number = maxDayCreateScoreInMonthNow();

  FixedAgreementEnum = FixedAgreement;

  assignments: Assignment[] = [];

  newCareerUpdate: CareerUpdate = new CareerUpdate({});

  showWarningIcon: boolean = false;

  messageDetails: string = 'Acesse a aba de remuneração no perfil do colaborador para visualizar o reajuste salarial!';

  currentBusinessUnitCode: number = 0;

  scoreIsRetroactive: boolean = false;

  roleOrSeniorityIsUpdated: boolean = false;

  currency: Currency = {
    name: '',
    description: '',
    symbol: '',
  };

  contractTypes: ContractType[] = ContractTypes;

  contractType!: ContractType;

  unlockBenefitPercent: boolean = false;

  async created() {
    this.loading = true;
    await this.getInitialData();

    if (this.comesFrom === this.scoreSource.employee && this.employeeId) {
      await this.getScoreByEmployeeId(this.employeeId);
      await this.checkIfAllowedCreateNewUnscheduleScore();
      return;
    }

    if (this.comesFrom === this.scoreSource.activity && this.activityId) {
      this.getScoreByActivityId(this.activityId);
    }
  }

  async newUnscheduleScore() {
    this.loading = true;
    this.enableScoreInicialData = false;
    this.readonlyMode = false;
    await this.setUnscheduleScoreInitialData();
  }

  async newScore(activityId: string) {
    await this.getScoreByEmployeeId(this.employeeId);
    await this.checkIsAllowedCreateScore();
    if (this.enableScoreInicialData) {
      this.loading = false;
      return;
    }
    await this.getActivityScore(activityId);
    if (this.checkDateNowIsLessThanActivityExpetedDate()) {
      this.changeScoreInicialMessage('Não recomendamos antecipar a contagem de pontos, pois alterará a data base de reajuste anual.');
      this.loading = false;
      this.enableScoreInicialData = true;
      this.enableNewUncheduleScoreButton = false;
      this.showWarningIcon = true;
      this.enableScoreDetailsMessage = true;
      this.messageDetails = 'Para reajustes fora do período de contagem, você poderá utilizar a funcionalidade de acordo salarial avulso, que se encontra na tela de Perfil do colaborador. Caso queira antecipar a contagem, entre em contato com a equipe de Gestão de Pessoas'
      return;
    }
    this.setScoreInitialData();
    this.enableScoreForm = true;
    this.loading = false;
  }

  async getScoreByEmployeeId(employeeId: string) {
    await ScoreService.getScoreByEmployeeId(employeeId)
      .then((resp: Score) => {
        this.score = resp;
        this.score.readjustmentPeriod = '';
      }).catch(error => VbSnackbarService.handleHttpError(error));
  }

  checkDateNowIsLessThanActivityExpetedDate() {
    const dateNow = moment().format('YYYY-MM-DD');
    const expetedDateFormated = moment(this.activity.expectedDate).format('YYYY-MM-DD');
    const yearNowIsBeforeExpetedDateYear = moment(dateNow).isBefore(expetedDateFormated, 'year');
    const monthNowIsBeforeExptedDateMonth = moment(dateNow).isBefore(expetedDateFormated, 'month');
    return monthNowIsBeforeExptedDateMonth || yearNowIsBeforeExpetedDateYear;
  }

  async checkIfAllowedCreateNewUnscheduleScore() {
    if (this.checkExistScoreInMonthNow()) {
      this.changeScoreInicialMessage();
      this.loading = false;
      this.enableNewUncheduleScoreButton = false;
      this.enableScoreDetailsMessage = true;
      this.enableScoreInicialData = true;
      return;
    }
    if (this.score.id === '' || this.score.id === null) {
      this.loading = false;
      this.enableScoreInicialData = true;
      this.enableScoreDetailsMessage = false
      this.enableNewUncheduleScoreButton = true;
      return;
    }
    this.scoreInitialMessage = `Reajuste realizado - ${this.score.formatCountDate}`
    this.loading = false;
    this.enableNewUncheduleScoreButton = true;
    this.enableScoreDetailsMessage = true;
    this.enableScoreInicialData = true;
  }

  changeScoreInicialMessage(message: string = 'Um reajuste salarial já foi realizado este mês') {
    this.scoreInitialMessage = message;
  }

  async checkIsAllowedCreateScore() {
    if (this.checkExistScoreInMonthNow()) {
      this.changeScoreInicialMessage();
      this.enableScoreInicialData = true;
      this.enableNewUncheduleScoreButton = false;
      this.enableScoreDetailsMessage = true;
      this.loading = false;
      this.enableScoreForm = false;
      return;
    }
    this.enableScoreInicialData = false;
  }

  checkExistScoreInMonthNow(): boolean {
    const monthNow = moment().month();
    const yearNow = moment().year();
    const countDateMonth = moment(this.score.countDate).month();
    const countDateYearNow = moment(this.score.countDate).year();
    return countDateMonth === monthNow && countDateYearNow === yearNow
  }

  openModal(): void {
    if (this.currency.name === 'BRL' && this.variableAgreementList.length > 0) {
      VbSnackbarService.showError('Transfira os valores dos acordos variáveis para Ducz (Sintinorp/Livre) ou Subisídio/Auxílio para Educação e depois remova os acordos variáveis');
      return;
    }
    if (this.refs.form.validate()) {
      this.checkIsYouChangedRoleOrSeniority();
    }
  }

  closeModal(): void {
    this.showModalWarning = false;
  }

  private getScoreByActivityId(activityId: string) {
    ScoreScheduleService.getScoreByActivityId(activityId)
      .then((resp: Score) => {
        this.score = resp;
        this.readonlyMode = true;
        this.loading = false;
        this.enableScoreInicialData = false;
      }).catch(() => {
        this.newScore(activityId);
      })
  }

  async getActivityScore(activityId: string) {
    await ScoreScheduleService.getActivityScore(activityId)
      .then((resp) => {
        this.activity = resp;
        this.score.activity = resp;
        this.score.employee = resp.employee;
      });
  }

  private getResponsible() {
    EmployeeService.getLoggedUserEmployeeId().then(
      (responsibleId) => {
        this.score.responsible.id = responsibleId
      },
    )
  }

  async setUnscheduleScoreInitialData() {
    this.score.employee.id = this.employeeId;
    this.getResponsible();
    this.score.unscheduledScore = true;
    this.setCountDate();
    this.addRoleSeniorityAndAssignment();
    this.setSalaryPrevious();
    this.loading = false;
    this.enableScoreForm = true;
  }

  setScoreInitialData() {
    this.score.unscheduledScore = false;
    this.getResponsible();
    this.setCountDate();
    this.setSalaryPrevious();
    this.addRoleSeniorityAndAssignment();
  }

  setCountDate() {
    this.score.countDate = moment().format(this.dateFullFormat);
  }

  setSalaryPrevious() {
    if (this.score.id) {
      const newFixedAgreementList: Agreement[] = this.setPreviousValueFixedAgreementOrdered();
      const newAgreementListNotFixed: Agreement[] = this.setPreviousValueAgreement();
      newFixedAgreementList.push(...newAgreementListNotFixed);
      this.score.agreementList = newFixedAgreementList;
    }
  }

  setPreviousValueFixedAgreementOrdered() {
    const newFixedAgreementList: Agreement[] = [];
    fixedAgreement.forEach((f) => {
      this.score.agreementList.forEach((agreement: Agreement) => {
        if (agreement.description === f) {
          newFixedAgreementList.push(Agreement.ofPrevious(agreement));
        }
      });
    })
    return newFixedAgreementList;
  }

  setPreviousValueAgreement() {
    const newAgreementListNotFixed: Agreement[] = [];
    this.score.agreementList.forEach((agreement: Agreement) => {
      if (fixedAgreement.includes(agreement.description)) return;
      newAgreementListNotFixed.push(Agreement.ofPrevious(agreement));
    })
    return newAgreementListNotFixed;
  }

  addRoleSeniorityAndAssignment() {
    if (this.score.id === '' || this.score.id === null) {
      this.editPrevious = false;
      this.fixedAgreementList.forEach((it) => {
        if (it.description === this.FixedAgreementEnum.ROLE) {
          it.previousAgreement = this.currentCareerUpdate.role.name!;
          it.newAgreement = this.currentCareerUpdate.role.name!;
        }
        if (it.description === this.FixedAgreementEnum.SENIORITY) {
          it.previousAgreement = this.currentCareerUpdate.seniority.name!;
          it.newAgreement = this.currentCareerUpdate.seniority.name!;
        }
        if (it.description === this.FixedAgreementEnum.ASSIGNMENT) {
          it.previousAgreement = this.currentCareerUpdate.assignment.description;
          it.newAgreement = this.currentCareerUpdate.assignment.description;
        }
      })
      return;
    }
    this.fixedAgreementList.forEach((it) => {
      if (it.description === this.FixedAgreementEnum.ROLE) {
        it.previousAgreement = it.previousAgreement === '' ? this.currentCareerUpdate.role.name! : it.newAgreement
        it.newAgreement = this.currentCareerUpdate.role.name!;
      }
      if (it.description === this.FixedAgreementEnum.SENIORITY) {
        it.previousAgreement = it.previousAgreement === '' ? this.currentCareerUpdate.seniority.name! : it.newAgreement;
        it.newAgreement = this.currentCareerUpdate.seniority.name!;
      }
      if (it.description === this.FixedAgreementEnum.ASSIGNMENT) {
        it.previousAgreement = it.previousAgreement === '' ? this.currentCareerUpdate.assignment.description : it.newAgreement;
        it.newAgreement = this.currentCareerUpdate.assignment.description;
      }
    })
  }

  applyReadJustmentPeriod(value: string) {
    this.score.readjustmentPeriod = value;
  }

  save() {
    this.score.updateTotal();
    this.createScore(this.score.toRequest());
  }

  createScore(score: Score) {
    if (this.scoreSourceIsActivity) {
      this.saveScore(score);
      return;
    }
    this.saveUnscheduledScore(score);
  }

  saveScore(score: Score) {
    this.loadingSaveButton = true;
    ScoreService.createScore(score)
      .then(() => {
        VbSnackbarService.showSuccess(this.getSuccessMessage());
        this.aware = false;
        this.loadingSaveButton = false;
        this.showModalWarning = false;
        this.$router.push('/schedule');
      })
      .catch((err: any) => {
        VbSnackbarService.handleHttpError(err);
        this.aware = false;
        this.loadingSaveButton = false;
        this.showModalWarning = false;
      });
  }

  saveUnscheduledScore(score: Score) {
    this.loadingSaveButton = true;
    ScoreService.createUnscheduledScore(score)
      .then(() => {
        VbSnackbarService.showSuccess(this.getSuccessMessage());
        this.aware = false;
        this.loadingSaveButton = false;
        this.showModalWarning = false;
        this.changeScoreInicialMessage();
        this.enableScoreInicialData = true;
        this.enableNewUncheduleScoreButton = false;
        this.enableScoreDetailsMessage = true;
        this.enableScoreForm = false;
        this.readonlyMode = false;
      })
      .catch((err: any) => {
        VbSnackbarService.handleHttpError(err);
        this.aware = false;
        this.loadingSaveButton = false;
        this.showModalWarning = false;
      });
  }

  get fixedAgreementList(): Agreement[] {
    const removeIndex = this.score.agreementList
      .findIndex(it => it.description === this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE);
    if (this.currency.name !== 'BRL' && removeIndex !== -1) {
      this.score.agreementList.splice(removeIndex, 1)
    }

    return this.score.agreementList
      .filter(it => fixedAgreement.includes(it.description))
  }

  get variableAgreementList(): Agreement[] {
    return this.score.agreementList
      .filter(it => !fixedAgreement.some(description => description === it.description)
        && it.description !== this.FixedAgreementEnum.TOTAL);
  }

  get totalAgreementList(): Agreement[] {
    return this.score.agreementList
      .filter(it => it.description === this.FixedAgreementEnum.TOTAL);
  }

  updateHealthPlanEmployee(value: boolean) {
    this.score.healthPlanEmployee = value;
    if (!value) {
      this.score.benefitObservation = '';
      this.score.healthPlanEmployeePayedByDB1 = false;
      this.score.healthPlan.healthPlanType = '';
    }
  }

  updateDentalPlanEmployee(value: boolean) {
    this.score.dentalPlanEmployee = value;
    if (!value) {
      this.score.dentalPlanEmployeePayedByDB1 = false;
    }
  }

  addDependent() {
    const dependent = ScoreDependentBenefits.of({})
    this.score.dependentBenefits.push(dependent)
  }

  addNewAgreement() {
    const total = this.score.agreementList.pop();
    this.score.agreementList.push(Agreement.of({ previousAgreement: 'NOT_EXISTS' }));
    if (total !== undefined) {
      this.score.agreementList.push(total);
    }
  }

  removeAgreement(item: Agreement) {
    const index = this.score.agreementList.indexOf(item);
    this.score.agreementList.splice(index, 1);
    this.updateTotal();
  }

  removeDependent(item: ScoreDependentBenefits) {
    const index = this.score.dependentBenefits.indexOf(item);
    this.score.dependentBenefits.splice(index, 1);
  }

  updateTotal() {
    this.score.updateTotal();
  }

  healthPLan(type: string): void {
    this.healthPLanList.forEach((it) => {
      if (it.healthPlanType === type) {
        this.score.healthPlan = HealthPlanDTO.of({});
        this.score.healthPlan = it;
      }
    })
  }

  updateHealthPlanEmployeePayedByDB1(healthPlanPayedByDB1: boolean): void {
    this.score.healthPlanEmployeePayedByDB1 = healthPlanPayedByDB1;
    if (healthPlanPayedByDB1) {
      this.healthPLan('REGION_INFIRMARY');
      return;
    }
    this.score.healthPlan.healthPlanType = '';
  }

  getCurrentCarrerUpdate() {
    EmployeeService.getUpdates(this.employeeId)
      .then((response) => {
        const currentCareerUpdate = response[0];
        this.currentCareerUpdate = currentCareerUpdate;
        if (currentCareerUpdate.businessUnit.code) {
          this.currentBusinessUnitCode = +currentCareerUpdate.businessUnit.code;
        }
      }).catch(error => VbSnackbarService.handleHttpError(error));
  }

  getHealthPlanList() {
    HealthPlanService.getHealthPLanList().then((resp) => {
      this.healthPLanList = HealthPlanDTO.ofList(resp)
    })
  }

  getRoles() {
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
  }

  getSeniorities() {
    CareerService.getSeniority().then((resp: any) => {
      this.seniorities = resp.data;
      resp.data.forEach((respSeniority) => {
        this.request.seniorities.push(
          {
            value: respSeniority.name,
            disabled: false,
          },
        )
      });
    });
  }

  getAssignments() {
    DrdService.getAssigments().then((response: Assignment[]) => this.assignments = response);
  }

  async getInitialData() {
    this.getCurrentCarrerUpdate();
    this.getRoles();
    this.getHealthPlanList();
    this.getSeniorities();
    this.getAssignments();
    this.getProfessionalData();
  }

  get senioritySorted() {
    return this.request.seniorities.sort((seniority1, seniority2) => {
      if (seniority1.value < seniority2.value) return -1;
      if (seniority1.value > seniority2.value) return 1;
      return 0;
    })
  }

  get scoreCountDate() {
    return moment(this.score.countDate).format('DD/MM/YYYY');
  }

  getSuccessMessage(): string {
    return this.comesFrom === this.scoreSource.activity ? 'Contagem realizada com sucesso!' : 'Acordo realizado com sucesso!';
  }

  async checkIsYouChangedRoleOrSeniority() {
    const scoreRole = this.score.agreementList
      .filter(agreement => agreement.description === this.FixedAgreementEnum.ROLE)[0];
    const scoreSeniority = this.score.agreementList
      .filter(agreement => agreement.description === this.FixedAgreementEnum.SENIORITY)[0];
    const scoreAssignment = this.score.agreementList
      .filter(agreement => agreement.description === this.FixedAgreementEnum.ASSIGNMENT)[0];
    const validationOpenCareerUpdateModal = this.currentCareerUpdate.role.name
    !== scoreRole.newAgreement
    || this.currentCareerUpdate.seniority.name !== scoreSeniority.newAgreement
    || this.currentCareerUpdate.assignment.description !== scoreAssignment.newAgreement;
    if (validationOpenCareerUpdateModal) {
      await this.setCareerUpdateInitialData(
        scoreRole.newAgreement,
        scoreSeniority.newAgreement,
        scoreAssignment.newAgreement,
      );
      this.roleOrSeniorityIsUpdated = true;
      this.openCareerUpdateModal();
      this.openScoreModalWarning();
      return;
    }
    this.roleOrSeniorityIsUpdated = false;
    this.openScoreModalWarning();
  }

  async setCareerUpdateInitialData(
    scoreRoleName: string,
    scoreSeniorityName: string,
    scoreAssignment: string,
  ) {
    this.newCareerUpdate = CareerUpdate.of(this.currentCareerUpdate);
    const filterSeniority = this.seniorities
      .filter(seniority => seniority.name === scoreSeniorityName)[0];
    const filterRole = this.roles.filter(role => role.name === scoreRoleName)[0];
    const filterAssignment = this.assignments
      .filter(assignment => assignment.description === scoreAssignment)[0];
    this.newCareerUpdate.seniority.name = filterSeniority.name;
    this.newCareerUpdate.seniority.id = filterSeniority.id;
    this.newCareerUpdate.role.name = filterRole.name;
    this.newCareerUpdate.role.id = filterRole.id;
    this.updateCareerUpdateAssignment(filterAssignment);
  }

  updateCareerUpdateAssignment(filterAssignment: Assignment) {
    this.newCareerUpdate.assignment.id = filterAssignment.id;
    this.newCareerUpdate.assignment.description = filterAssignment.description;
    this.newCareerUpdate.assignment.ecosystem = filterAssignment.ecosystem;
    this.newCareerUpdate.assignment.inactive = filterAssignment.inactive;
    this.newCareerUpdate.assignment.role = filterAssignment.role;
  }

  openScoreModalWarning() {
    this.updateModalWarningModal();
    this.showModalWarning = true;
  }

  openCareerUpdateModal() {
    this.showCareerUpdateModal = true;
    this.$nextTick(() => {
      const { careerUpdateModal }: any = this.$refs;
      careerUpdateModal.setFields();
    })
  }

  closeCareerUpdateModal() {
    if (this.saveCarrerUpdate) {
      this.showCareerUpdateModal = false;
      return;
    }
    this.showModalWarning = false;
    this.showCareerUpdateModal = false;
  }

  saveNewCareerUpdate(careerUpdate: CareerUpdate) {
    this.loadingSaveCarrerUpdateButton = true;
    VbLoadingService.show();
    CareerService.saveCareer(this.score.employee.id, careerUpdate.setUpdateValues())
      .then((response: any) => {
        this.saveCarrerUpdate = true;
        this.currentCareerUpdate = response;
        VbSnackbarService.showSuccess('Evento salvo com sucesso');
        this.closeCareerUpdateModal();
        this.openScoreModalWarning();
      })
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingSaveCarrerUpdateButton = false;
        VbLoadingService.hide();
      });
  }

  back() {
    this.$router.push('/schedule');
  }

  get scoreSourceIsActivity(): boolean {
    return this.comesFrom === this.scoreSource.activity;
  }

  get scoreSourceIsEmployee(): boolean {
    return this.comesFrom === this.scoreSource.employee;
  }

  updateDentalPlanEmployeePayedByDB1(value: boolean) {
    this.score.dentalPlanEmployeePayedByDB1 = value;
  }

  updateLifeInsuranceEmployee(value: boolean) {
    this.score.lifeInsuranceEmployee = value;
  }

  updateBenefitObservation(value: string) {
    this.score.benefitObservation = value;
  }

  isRetroactive(): boolean {
    const [month, year] = this.score.readjustmentPeriod.split('/')
    const today = new Date();
    const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1).getTime();
    const firstDayOfReadjustmentMonth = new Date(Number(year), Number(month) - 1, 1).getTime();
    if (firstDayOfReadjustmentMonth === firstDayOfCurrentMonth) {
      this.scoreIsRetroactive = today.getDate() > maxDayCreateScoreInMonthNow();
      return this.scoreIsRetroactive;
    }
    this.scoreIsRetroactive = firstDayOfReadjustmentMonth < firstDayOfCurrentMonth;
    return this.scoreIsRetroactive;
  }

  updateModalWarningModal() {
    if (this.scoreIsRetroactive && !this.roleOrSeniorityIsUpdated) {
      this.modalWarningMessage = 'Ao salvar o acordo, ele não poderá mais ser editado. Deseja finalizar? A senioridade e cargos não foram alterados, após finalizar, por favor verificar os dados de carrerira do colaborador. Gestor, esse é um reajuste retroativo, assim, os ajustes serão aplicados apenas na próxima competência de pagamento!';
      return;
    }
    if (this.scoreIsRetroactive && !this.roleOrSeniorityIsUpdated) {
      this.modalWarningMessage = 'Deseja finalizar o reajuste salarial? Ao clicar em finalizar o mesmo não poderá mais ser alterado. Gestor, esse é um reajuste retroativo, assim, os ajustes serão aplicados apenas na próxima competência de pagamento!';
      return;
    }
    if (!this.scoreIsRetroactive && this.roleOrSeniorityIsUpdated) {
      this.modalWarningMessage = 'Deseja finalizar o reajuste salarial? Ao clicar em finalizar o mesmo não poderá mais ser alterado!';
      return;
    }
    this.modalWarningMessage = 'Ao salvar o acordo, ele não poderá mais ser editado. Deseja finalizar? A senioridade e cargos não foram alterados, após finalizar, por favor verificar os dados de carrerira do colaborador.';
  }

  getCurrency() {
    EmployeeService.getCurrencyByEmployeeId(this.employeeId)
      .then((currency: Currency) => this.currency = currency);
  }

  async getProfessionalData() {
    const {
      currency,
      contractType,
      unlockBenefitPercent,
    } = await EmployeeService.getProfessionalData(this.employeeId);
    this.currency = currency;
    this.contractType = contractType;
    this.unlockBenefitPercent = unlockBenefitPercent;
  }
}
