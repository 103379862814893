



















































































import { Vue, Component } from 'vue-property-decorator';
import ExpansionPanel from '@/shared/expansion-panel/expansion-panel.component.vue';
import InternalMovementService from '@/domain/internal-movement/service/internal-movement.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import LabelSlot from 'bag-of-holding-library/src/slot/label-slot.component.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import EmployeeSelect from '@/shared/employee/employee-select.vue';
import InternalMovement from '@/domain/internal-movement/internal-movement.entity';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import InternalMovementChangeStatusResponse from '@/domain/internal-movement/entity/internal-movement-change-status-response.entity';
import Loading from '@/shared/loading/loading.component.vue';
import CreateUpdateModal from '@/views/employees/profile/modal/create-update-modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import CareerUpdate from '@/domain/employees/entity/career-update.entity';
import InternalMovementEmployeeDetailsComponent from './internal-movement-employee-details.component.vue';
import InternalMovementEmployeeKnowledgesComponent from './internal-movement-employee-knowledges.component.vue';
import InternalMovementDetailsComponent from './internal-movement-details.component.vue';
import InternalMovementActionListComponent from './internal-movement-action-list.component.vue';

@Component({
  components: {
    ExpansionPanel,
    LabelSlot,
    DatePickerField,
    EmployeeSelect,
    ModalWarning,
    InternalMovementEmployeeDetailsComponent,
    InternalMovementEmployeeKnowledgesComponent,
    InternalMovementDetailsComponent,
    InternalMovementActionListComponent,
    Loading,
    CreateUpdateModal,
  },
})
export default class InternalMovementFormComponent extends Vue {
  internalMovementId: string = this.$route.params.id;

  loading: boolean = false;

  internalMovement: InternalMovement = new InternalMovement();

  loadingUpdate: boolean = false;

  showCareerUpdateModal: boolean = false;

  loadingCreateCareerUpdate: boolean = false;

  loadingCancel: boolean = false;

  openModalCancel: boolean = false;

  cancelObservation: string = '';

  cancelModalOptions: ModalOptions = {
    title: 'Cancelar Movimentação Interna',
    description: 'Deseja realmente cancelar a movimentação interna? Informe uma observação para justificar o cancelamento.',
    showOk: true,
    width: 600,
    showCancel: true,
    labelOk: 'Cancelar',
    labelCancel: 'Fechar',
    okColor: 'error',
    loadingOk: false,
  };

  created() {
    this.getInternalMovement();
  }

  async getInternalMovement() {
    try {
      this.loading = true;
      const response = await InternalMovementService.getById(this.internalMovementId);
      this.internalMovement = new InternalMovement(response);
    } catch (error) {
      VbSnackbarService.handleHttpError(error);
      this.$router.push({ name: 'internal-movement-list' });
    } finally {
      this.loading = false;
    }
  }

  async update() {
    const data = this.internalMovement.toUpdate();
    try {
      this.loadingUpdate = true;
      const response = await InternalMovementService.update(this.internalMovementId, data);
      this.internalMovement.update(response);
      VbSnackbarService.showSuccess('Movimentação interna atualizada com sucesso');
    } catch (error) {
      VbSnackbarService.handleHttpError(error);
    } finally {
      this.loadingUpdate = false;
    }
  }

  started(data: InternalMovementChangeStatusResponse) {
    this.internalMovement.startedDate = data.startedDate;
    this.internalMovement.status = data.status;
    this.internalMovement.responsibleName = data.responsibleName;
    this.internalMovement.responsibleId = data.responsibleId;
  }

  handleOpenCareerUpdateModal() {
    this.showCareerUpdateModal = true;
  }

  async createCareerUpdate(careerUpdate: CareerUpdate) {
    try {
      this.loadingCreateCareerUpdate = true;
      const body = careerUpdate.setUpdateValues();
      const response = await InternalMovementService.conclude(this.internalMovementId, body);
      VbSnackbarService.showSuccess('Movimentação interna concluída com sucesso');
      this.internalMovement.concludedDate = response.startedDate;
      this.internalMovement.status = response.status;
      this.showCareerUpdateModal = false;
    } catch (error) {
      VbSnackbarService.handleHttpError(error);
    } finally {
      this.loadingCreateCareerUpdate = false;
    }
  }

  handleOpenCancelModal() {
    this.openModalCancel = true;
  }

  cancel() {
    this.loadingCancel = true;
    this.cancelModalOptions.loadingOk = true;
    this.cancelModalOptions.showCancel = false;
    InternalMovementService.cancel(this.internalMovementId, this.cancelObservation)
      .then(() => {
        VbSnackbarService.showSuccess('Movimentação interna cancelada com sucesso');
        this.$router.push({ name: 'Listagem Movimentações Internas' });
      })
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingCancel = false;
        this.openModalCancel = false;
        this.cancelModalOptions.loadingOk = false;
        this.cancelModalOptions.showCancel = true;
      });
  }
}
