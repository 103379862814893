import DateFormat from 'bag-of-holding-library/src/utils/date-format';

export default class InternalMovementList {
  id: string

  employeeName: string;

  businessUnitName: string;

  requestDate: string;

  status: string;

  startedDate: string;

  concludedDate: string;

  talentLevelName: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.employeeName = obj.employeeName || '';
    this.businessUnitName = obj.businessUnitName || '';
    this.requestDate = obj.requestDate || '';
    this.status = obj.status || '';
    this.startedDate = obj.startedDate || '';
    this.concludedDate = obj.concludedDate || '';
    this.talentLevelName = obj.talentLevelName || '';
  }

  get requestDateFormatted() {
    return this.requestDate === '' ? '' : DateFormat.standardDate(this.requestDate);
  }

  get startedDateFormatted() {
    return this.startedDate === '' ? '' : DateFormat.standardDate(this.startedDate);
  }

  get concludedDateFormatted() {
    return this.concludedDate === '' ? '' : DateFormat.standardDate(this.concludedDate);
  }
}
