import { render, staticRenderFns } from "./regular-component.vue?vue&type=template&id=600502f3&scoped=true&"
import script from "./regular-component.vue?vue&type=script&lang=ts&"
export * from "./regular-component.vue?vue&type=script&lang=ts&"
import style0 from "./regular-component.vue?vue&type=style&index=0&id=600502f3&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "600502f3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VTextarea})
