







































































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import MobileHandler from '@/shared/utils/mobile-handler';
import DateHandler from '@/shared/utils/date-format';
import Rules from '@/shared/rules/rules';
import PayslipService from '@/domain/employees/service/payslip.service';
import PayslipList from '@/domain/employees/entity/payslip-list.entity';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import EmployeeSelect from '@/shared/employee/employee-select.vue';

@Component({
  components: {
    CardTitle,
    LabelSlot,
    MonthYearSelect,
    EmployeeSelect,
    VbTooltipSlot,
  },
})
export default class PayslipComponent extends Vue {
  rules: Rules = new Rules();

  competence = '';

  payslipList: PayslipList[] = [];

  isMobile: boolean = false;

  key: number = 0;

  async save() {
    const competenceFormatted = this.getFormattedCompetence();
    const uploadResults = await this.uploadPayslips(competenceFormatted);

    this.filterPayslipList(uploadResults);
    this.incrementKey();
  }

  getFormattedCompetence(): string {
    return DateHandler.monthYearFromFormated(this.competence);
  }

  async uploadPayslips(competenceFormatted: string): Promise<(PayslipList | null)[]> {
    return Promise.all(
      this.payslipList.map(file => this.uploadSinglePayslip(file, competenceFormatted)),
    );
  }

  async uploadSinglePayslip(file: PayslipList, competenceFormatted: string):
    Promise<PayslipList | null> {
    this.setFileLoading(file, true);
    try {
      const { data }: any = await PayslipService.uploadPayslip(competenceFormatted, file);
      return this.handleSuccessfulUpload(file, data);
    } catch (err) {
      this.handleUploadError(err);
      return null;
    } finally {
      this.setFileLoading(file, false);
    }
  }

  setFileLoading(file: PayslipList, state: boolean): void {
    file.loading = state;
  }

  handleSuccessfulUpload(file: PayslipList, data: any): PayslipList {
    file.id = data.id;
    if (data.employeeId) {
      file.employeeName = data.employeeName;
      file.employeeId = data.employeeId;
      file.employee = EmployeeSummary.of({
        name: data.employeeName,
        id: data.employeeId,
      });
    }
    return file;
  }

  handleUploadError(err: any): void {
    VbSnackbarService.handleHttpError(err);
  }

  filterPayslipList(uploadResults: (PayslipList | null)[]): void {
    this.payslipList = uploadResults.filter((file): file is PayslipList => file !== null);
  }

  incrementKey(): void {
    this.key += 1;
  }

  handleIsMobile() {
    this.isMobile = MobileHandler.isLessThan(600);
  }

  updatePayslipList(payslipList: PayslipList[]) {
    this.payslipList = payslipList;
    this.save();
  }

  clearPayslipList() {
    this.payslipList = [];
  }

  clearEmployee(index: number) {
    this.payslipList[index].employee = null;
    this.payslipList[index].employeeId = '';
    this.payslipList[index].employeeName = '';
  }

  async includeEmployee(employee: EmployeeSummary, index: number) {
    await PayslipService.linkEmployee(employee.id, this.payslipList[index].id)
    this.payslipList[index].employeeId = employee.id;
    this.payslipList[index].employeeName = employee.name;
    this.payslipList[index].employee = employee;
  }

  async updateEmployee(employee: EmployeeSummary | null, index: number) {
    this.incrementKey();
    this.setFileLoading(this.payslipList[index], true);
    if (!employee) {
      this.clearEmployee(index);
      this.setFileLoading(this.payslipList[index], false);
      this.incrementKey();
      return;
    }
    await this.includeEmployee(employee, index);
    this.setFileLoading(this.payslipList[index], false);
    this.incrementKey();
  }
}
