import SkillType from '@/domain/employees/entity/skill-type.entity';

export default class InternalMovementKnowledge {
  id: string;

  description: string;

  type: SkillType;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.description = obj.description || '';
    this.type = obj.type || '';
  }
}
