import AuthorityEnum from './authority.enum';

export default class Authority {
  private authorities: string[];

  constructor(authorities: string[]) {
    this.authorities = authorities;
  }

  hasAuthorities(match: string[]): boolean {
    if (this.authorities && match) {
      const result = this.authorities.find(value => match.includes(value));
      return (result && result.length > 0) || false;
    }
    return false;
  }

  hasCreateDrd(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_DRD]);
  }

  hasUpdateDrd(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_DRD]);
  }

  hasAccessGodfatherExport(): boolean {
    return this.hasAuthorities([AuthorityEnum.EXPORT_GODFATHER_DATA]);
  }

  hasAccessPersonalDataExport(): boolean {
    return this.hasAuthorities([AuthorityEnum.EXPORT_PERSONAL_DATA])
  }

  hasAccessAttendance(): boolean {
    return this.hasAuthorities([
      AuthorityEnum.READ_ATTENDANCE,
      AuthorityEnum.UPDATE_ATTENDANCE,
      AuthorityEnum.CREATE_ATTENDANCE,
      AuthorityEnum.DELETE_ATTENDANCE,
    ])
  }

  hasAccessResignations(): boolean {
    return this.hasAuthorities([
      AuthorityEnum.UPDATE_RESIGNATION,
      AuthorityEnum.READ_RESIGNATION,
      AuthorityEnum.DELETE_RESIGNATION,
    ])
  }

  hasAccessAssignments(): boolean {
    return this.hasAuthorities([
      AuthorityEnum.READ_ASSIGNMENTS,
      AuthorityEnum.INACTIVE_ASSIGNMENTS,
      AuthorityEnum.UPDATE_ASSIGNMENTS,
      AuthorityEnum.CREATE_ASSIGNMENTS,
    ])
  }

  hasAccessRoles(): boolean {
    return this.hasAuthorities([
      AuthorityEnum.READ_ROLES,
      AuthorityEnum.UPDATE_ROLES,
      AuthorityEnum.INACTIVE_ROLES,
    ])
  }

  hasAccessSecurity(): boolean {
    return this.hasAuthorities([
      AuthorityEnum.READ_SECURITY,
      AuthorityEnum.UPDATE_SECURITY,
    ])
  }

  hasAccessTalentLevel(): boolean {
    return this.hasAuthorities([
      AuthorityEnum.READ_TALENT_LEVEL,
      AuthorityEnum.CREATE_TALENT_LEVEL,
      AuthorityEnum.UPDATE_TALENT_LEVEL,
      AuthorityEnum.INACTIVE_TALENT_LEVEL,
    ]);
  }

  hasAccessMessageDaisy(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_MESSAGE_DAISY])
  }

  isAdministrator(): boolean {
    return this.hasAuthorities([AuthorityEnum.ADMINISTRATOR]);
  }

  isManager(): boolean {
    return this.hasAuthorities([AuthorityEnum.MANAGER]);
  }

  isEmployeeAdministrator(): boolean {
    return this.hasAuthorities([AuthorityEnum.EMPLOYEE_ADMINISTRATOR]);
  }

  isFacilitiesAdministrator(): boolean {
    return this.hasAuthorities([AuthorityEnum.FACILITIES_ADMINISTRATOR]);
  }

  isInternalMovementResponsible(): boolean {
    return this.hasAuthorities([AuthorityEnum.INTERNAL_MOVEMENT_RESPONSIBLE]);
  }

  isTrainingAdministrator(): boolean {
    return this.hasAuthorities([AuthorityEnum.TRAINING_ADMINISTRATOR]);
  }

  isAppraiser(): boolean {
    return this.hasAuthorities([AuthorityEnum.APPRAISER]);
  }

  isBusinnesPartner(): boolean {
    return this.hasAuthorities([AuthorityEnum.BUSINESS_PARTNER]);
  }

  isPersonalDepartment(): boolean {
    return this.hasAuthorities([AuthorityEnum.PERSONAL_DEPARTMENT]);
  }

  isSkillManagement(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_SKILL_MANAGEMENT]);
  }

  isSkillMapCreate(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_SKILL]);
  }

  isSkillMapUpdate(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_SKILL]);
  }

  isSkillMapDelete(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_SKILL]);
  }

  isSkillMap(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_SKILL_MAP]);
  }

  isSkillMapTab(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_SKILL_MAP_TAB]);
  }

  hasAccessRiskAttendance(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_RISK_ATTENDANCE]);
  }

  hasCreateRiskAttendance(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_RISK_ATTENDANCE]);
  }

  hasUpdateRiskAttendance(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_RISK_ATTENDANCE]);
  }

  hasDeleteRiskAttendance(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_RISK_ATTENDANCE]);
  }

  hasAccessWages(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_WAGES]);
  }

  hasAccessImportVacationPayslip(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPLOAD_PAYSLIP])
  }

  isReadSchedule(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_SCHEDULE]);
  }

  isReadActivity(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_ACTIVITY]);
  }

  isUpdateActivity(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_ACTIVITY]);
  }

  isUpdateActivityStatus(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_ACTIVITY_STATUS]);
  }

  isUploadFiles(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPLOAD_FILES]);
  }

  isCreateSession(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_SESSION]);
  }

  isDeleteActivity(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_ACTIVITY]);
  }

  isReadActivityHistory(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_ACTIVITY_HISTORY]);
  }

  isResetEmployeeSchedule(): boolean {
    return this.hasAuthorities([AuthorityEnum.RESET_EMPLOYEE_SCHEDULE]);
  }

  isReadTableSalary(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_TABLE_SALARY]);
  }

  isUpdateTableSalary(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_TABLE_SALARY]);
  }

  isReadVacation(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_VACATIONS]);
  }

  isCreateVacation(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_VACATIONS]);
  }

  isUpdateVacation(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_VACATIONS]);
  }

  isDeleteVacation(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_VACATIONS]);
  }

  isVacationBalance(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_VACATION_BALANCE])
  }

  isReadTalentLevel(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_TALENT_LEVEL])
  }

  isCreateTalentLevel(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_TALENT_LEVEL])
  }

  isUpdateTalentLevel(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_TALENT_LEVEL])
  }

  isInactiveTalentLevel(): boolean {
    return this.hasAuthorities([AuthorityEnum.INACTIVE_TALENT_LEVEL])
  }

  isUpdateSecurity(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_SECURITY]);
  }

  isReadSalaryReadjustmentParametrization(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_SALARY_READJUSTMENT]);
  }

  hasCreateSalaryReadjustmentParametrization(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_SALARY_READJUSTMENT]);
  }

  hasUpdateProfessionalData(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_PROFESSIONAL_DATA])
  }

  hasAccessProfessionalData(): boolean {
    return this
      .hasAuthorities([
        AuthorityEnum.READ_PROFESSIONAL_DATA,
        AuthorityEnum.UPDATE_PROFESSIONAL_DATA,
      ]);
  }

  hasCreateOrUpdateEmployeeScore(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_OR_UPDATE_SCORE]);
  }

  hasReadScore(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_SCORE]);
  }

  hasCreateResignation(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_RESIGNATION]);
  }

  hasUpdateGodfather(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_GODFATHER]);
  }

  hasUpdateAcompanied(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_ACOMPANIED]);
  }

  hasUpdateNeedTraining(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_NEED_TRAINING]);
  }

  hasCreateCarrerUpdate(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_CARRER_UPDATE]);
  }

  hasCreateInternalMovement(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_INTERNAL_MOVEMENT]);
  }

  hasUpdateCarrerUpdate(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_CARRER_UPDATE]);
  }

  hasDeleteCarrerUpdate(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_CARRER_UPDATE]);
  }

  hasDeletePastCarrerUpdate(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_PAST_CAREER_EVENT]);
  }

  hasReadCarrerUpdateHistory(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_CARRER_UPDATE_HISTORY]);
  }

  hasReadPersonalData(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_PERSONAL_DATA])
  }

  hasReadEmployeeRemunaration(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_EMPLOYEE_SCORE])
  }

  hasReadEmployeeActivity(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_EMPLOYEE_ACTIVITY])
  }

  hasReadAgreement(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_AGREEMENT]);
  }

  hasUpdateSalaryReadjustmentParamentrization(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_SALARY_READJUSTMENT])
  }

  hasDeleteSalaryReadjustmentParametrization(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_SALARY_READJUSTMENT]);
  }

  hasUpdatePastCareerUpdateEvent(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_PAST_CAREER_EVENT])
  }

  hasUpdateReceiveCommission(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_RECEIVE_COMMISSION])
  }

  hasActiveOrInactiveActivityHistory(): boolean {
    return this.hasAuthorities([AuthorityEnum.ACTIVE_OR_INACTIVE_ACTIVITY_HISTORY])
  }

  hasAccessCreateInitialScore(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_INITIAL_SCORE])
  }

  hasAccessReadSelectiveProcess(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_SELECTIVE_PROCESS]);
  }

  isReadClassification(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_CLASSIFICATION]);
  }

  isUpdateClassification(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_CLASSIFICATION]);
  }

  hasCreatePdiPlan(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_PDI_PLAN]);
  }

  hasUpdatePdiPlan(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_PDI_PLAN]);
  }

  hasDeletePdiPlan(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_PDI_PLAN]);
  }

  hasAccessPdiTab(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_PDI_TAB]);
  }

  hasAccessReadIndicatorDefaultManagement(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_INDICATOR_DEFAULT]);
  }

  hasCreateIndicatorDefault(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_INDICATOR_DEFAULT]);
  }

  hasUpdateIndicatorDefault(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_INDICATOR_DEFAULT]);
  }

  hasInactiveIndicatorDefault(): boolean {
    return this.hasAuthorities([AuthorityEnum.INACTIVE_INDICATOR_DEFAULT]);
  }

  hasUpdateAttendance(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_ATTENDANCE]);
  }

  hasDeleteAttedance(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_ATTENDANCE])
  }

  hasReadAttedance(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_ATTENDANCE])
  }

  hasCreateAttedance(): boolean {
    return this.hasAuthorities([AuthorityEnum.CREATE_ATTENDANCE])
  }

  hasCreateAssignments() {
    return this.hasAuthorities([AuthorityEnum.CREATE_ASSIGNMENTS])
  }

  hasUpdateAssignments() {
    return this.hasAuthorities([AuthorityEnum.UPDATE_ASSIGNMENTS])
  }

  hasInactiveAssignments() {
    return this.hasAuthorities([AuthorityEnum.INACTIVE_ASSIGNMENTS])
  }

  hasUpdateDrds(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_DRD])
  }

  hasUpdateRoles(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_ROLES]);
  }

  hasInactiveRoles(): boolean {
    return this.hasAuthorities([AuthorityEnum.INACTIVE_ROLES]);
  }

  hasReadRoles(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_ROLES]);
  }

  hasExportGodfatherData(): boolean {
    return this.hasAuthorities([AuthorityEnum.EXPORT_GODFATHER_DATA]);
  }

  hasExportPersonalData(): boolean {
    return this.hasAuthorities([AuthorityEnum.EXPORT_PERSONAL_DATA]);
  }

  hasDeleteResignations(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_RESIGNATION])
  }

  hasUpdateResignations(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_RESIGNATION]);
  }

  hasReadAssessmentResult(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_ASSESSMENT_RESULT]);
  }

  hasReadhasReadSkillKeyArea(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_SKILL_KEYAREA]);
  }

  isReadEmployeeTalentLevel(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_EMPLOYEE_TALENT_LEVEL]);
  }

  hasUpdateContractType(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_CONTRACT_TYPE]);
  }

  hasUnlockBenefitPercent(): boolean {
    return this.hasAuthorities([AuthorityEnum.UPDATE_UNLOCK_BENEFIT_PERCENT])
  }

  isOnlyManager() : boolean {
    return this.isManager() && !(this.isAdministrator()
      || this.isFacilitiesAdministrator()
      || this.isEmployeeAdministrator()
      || this.isBusinnesPartner()
      || this.isPersonalDepartment());
  }

  isOthersGroups() {
    return this.isAdministrator()
      || this.isFacilitiesAdministrator()
      || this.isEmployeeAdministrator()
      || this.isBusinnesPartner()
      || this.isPersonalDepartment()
      || this.isInternalMovementResponsible()
  }

  hasAccessImportEmployeePayslip(): boolean {
    return this.hasAuthorities([AuthorityEnum.IMPORT_EMPLOYEE_PAYSLIP])
  }

  hasAccessPayslip(): boolean {
    return this.hasAuthorities([AuthorityEnum.READ_EMPLOYEE_PAYSLIP])
  }

  hasAccessDownloadPayslip(): boolean {
    return this.hasAuthorities([AuthorityEnum.DOWNLOAD_EMPLOYEE_PAYSLIP])
  }

  hasAccessDeletePayslip(): boolean {
    return this.hasAuthorities([AuthorityEnum.DELETE_EMPLOYEE_PAYSLIP])
  }
}
