






















































































































































































































































































































































































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import Resignation from '@/domain/resignation/entity/resignation.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import ManagerService from '@/domain/employees/service/employee.service';
import State from '@/domain/states/entity/state.entity';
import City from '@/domain/states/entity/city.entity';
import StateService from '@/domain/states/service/states.service';
import ResignationService from '@/domain/resignation/service/resignation.service';
import ResignationRequest from '@/domain/resignation/entity/resignation-request.entity';
import CardTitle from '@/shared/card/card-title.component.vue';
import PersonalData from '@/domain/employees/entity/personal-data.entity';
import demissionTypes from './demission-types.json';
import devolutionTypes from './devolution-types.json';
import ResignationSelectBackupTypes from './resignation-select-backup-types.component.vue';

  @Component({
    components: {
      ModalComponent,
      LabelSlot,
      VbTooltipSlot,
      DatePickerField,
      CardTitle,
      ResignationSelectBackupTypes,
    },
  })

export default class ResignationModal extends Vue {
  @Prop() show!: boolean;

  @Prop() showResignationModal!: boolean;

  @Prop() employeeId!: string;

  @Prop() personalData!: PersonalData;

  resignation: Resignation = Resignation.of({});

  valid: boolean = false;

  rules: Rules = new Rules();

  role: any = '';

  loading = false;

  states: State[] = [];

  cities: City[] = [];

  file: any = {};

  refs: any = this.$refs

  managers: EmployeeSummary[] = [];

  employees: EmployeeSummary[] = [];

  responsibles: EmployeeSummary[] = [];

  lastFilterManager: string = '';

  lastFilterEmployee: string = '';

  lastFilterResponsible: string = '';

  noticeStartKey = 0;

  booleanSelectOptions = [
    {
      text: 'Sim',
      value: true,
    },
    {
      text: 'Não',
      value: false,
    },
  ]

  updateNoticeStartDate() {
    if (!this.resignation.complyNotice) {
      this.resignation.noticeStartDate = '';
      this.noticeStartKey += 1;
    }
  }

  demissionTypes = demissionTypes;

  complyNotices = [
    {
      value: false,
      description: 'Não',
    },
    {
      value: true,
      description: 'Sim',
    },
  ];

  devolutionTypes = devolutionTypes;

  get optionsHandled(): ModalOptions {
    return {
      title: '',
      titleSize: '0px',
      description: '',
      showOk: true,
      labelOk: 'Salvar',
      loadingOk: this.loading,
      okColor: 'tabBlue',
      showCancel: !this.loading,
      labelCancel: 'Cancelar',
      width: 1500,
    };
  }

  save() {
    if (this.refs.form.validate()) {
      if (this.resignation.isObservationValid()) {
        const request = ResignationRequest.of(this.resignation);
        this.loading = true;
        if (!this.resignation.isVoluntary()) {
          this.file = new Blob();
        }
        ResignationService.save(request, this.file)
          .then((resp: any) => {
            VbSnackbarService.showSuccess('Desligamento enviado com sucesso. Por favor, verifique sua caixa de e-mail.');
            this.loading = false;
            this.close();
          })
          .catch((err: any) => VbSnackbarService.handleHttpError(err))
          .finally(() => this.loading = false);
      } else if ((!this.resignation.observation)) {
        VbSnackbarService.showError('Para desligamentos após o horário limite, favor informar motivo na observação');
      } else {
        VbSnackbarService.showError('Observação deve conter no mínimo 20 caracteres');
      }
    }
  }

  close() {
    if (!this.loading) {
      this.clear();
      this.$emit('close');
    }
  }

  clear() {
    this.role = '';
  }

  created() {
    this.applyEmployeeId();
  }

  @Watch('employeeId')
  applyEmployeeId() {
    this.resignation.employee = this.employeeId;
  }

  @Watch('personalData')
  @Watch('show')
  applyPersonalData() {
    this.initiStates();
    this.resignation.employeeEmail = this.personalData.privateEmail;
    this.resignation.state = this.personalData.state.id;
    this.getCities();
    this.resignation.city = this.personalData.city.id;
    this.resignation.employeeCellPhone = this.personalData.cellPhone;
  }

  initiStates() {
    if (this.show && this.states.length === 0) {
      StateService.findStates()
        .then((resp: any) => this.states = resp);
    }
  }

  getCities() {
    if (!this.resignation.state) return;
    StateService.findCities(this.resignation.state)
      .then((resp: any) => this.cities = resp);
  }

  findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        EmployeeService.findEmployeesByName(value)
          .then((resp: EmployeeSummary[]) => this.employees = resp);
      }
    }
  }

  findResponsibleByName(value: string) {
    if ((this.lastFilterResponsible !== value) && value) {
      this.lastFilterResponsible = value;
      if (value.length > 2) {
        EmployeeService.findEmployeesByNameAndIsReponsible(value, true)
          .then((resp: EmployeeSummary[]) => this.responsibles = resp);
      }
    }
  }

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        ManagerService.findManagersByName(value)
          .then((resp: EmployeeSummary[]) => this.managers = resp);
      }
    }
  }

  setBackupTypes(value: string[]) {
    this.resignation.backupTypes = value;
  }

  clearFile() {
    this.file = undefined;
  }
}
