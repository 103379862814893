import moment from 'moment';
import FilterUtils from '@/shared/utils/filters';

export default class Rules {
    readonly name = [
      (value: string) => !!value || 'Campo obrigatório.',
      (value: string) => (!!value && value.length > 2) || 'Campo deve ter mais que 2 caracteres.',
      (value: string) => (!!value && value.length < 100) || 'Campo deve ter no máximo 100 caracteres.',
    ]

    readonly empty = [
      (value: string) => (value !== null && value !== undefined && !!value.replace(/\s/g, '')) || 'Campo obrigatório.',
    ]

    readonly emptyNumber = [
      (value: any) => {
        if (typeof value === 'string') {
          const val = value.toString();
          return (val !== undefined && val !== null && !!val.replace(/\s/g, '')) || 'Campo obrigatório.';
        }
        return (value !== undefined && value !== null) || 'Campo obrigatório.';
      },
    ]

    readonly emptyFile = [
      (value: any) => (value !== undefined) || 'Campo obrigatório.',
    ]

    readonly emptyObject = [
      (value: any) => ((value !== undefined || value !== null)) || 'Campo obrigatório.',
      (value: any) => (value && Object.keys(value).length !== 0 && (Object.values(value).some(it => it !== null || it !== ''))) || 'Campo obrigatório.',
    ]

    readonly totalTime = [
      (value: string) => (!!value && parseFloat(value) >= 0 && parseFloat(value) < 24.1) || 'Valor inválido.',
    ]

    readonly moneyEqualOrBiggerThanZero = [
      (value: string) => (!!value && (Number(FilterUtils.formatValue(`${value}`))) >= 0.0) || 'Valor inválido',
    ]

    readonly grade = [
      (value: string) => (!!value && parseFloat(value) >= 0 && parseFloat(value) < 10.1) || 'Valor inválido.',
    ]

    readonly satisfaction = [
      (value: string) => (!!value && parseFloat(value) >= 0 && parseFloat(value) < 100.1) || 'Valor inválido.',
    ]

    readonly greaterThanTen = [
      (value: string) => (!!value && parseFloat(value) >= 5.93) || 'Reajuste deve ser maior ou igual a 5.93%.',
    ]

    readonly atLeastOneFieldFilled = [
      (value: any) => (!!value && !Object.values(value).every(el => el === '')) || 'Campo inválido.',
    ]

    readonly emailFormat = [
      (value: any) => (!!value && /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi.test(value)) || 'Formato de email inválido',
    ]

    readonly cpf = [
      (value: any) => (!!value && /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value)) || 'Formado de CPF inválido',
    ]

    readonly db1EmailFormat = [
      (value: any) => (!!value && /^\w+([.-]?\w+)*@db1\.com\.br$/.test(value)) || 'Formato de email DB1 inválido',
    ]

    readonly newEmailFormat = [
      (value: any) => !!value || 'E-mail é necessário',
      (value: any) => /.+@.+/.test(value) || 'Formato de e-mail inválido',
    ]

    readonly invalidDate = [
      (value: string) => {
        const momentDate = moment(value, 'DD/MM/YYYY');
        if (!momentDate.isValid()) {
          return 'Data de atendimento inválida'
        }
        return momentDate.isSameOrBefore(moment()) || 'Data não pode ser maior que a atual';
      },
    ]

    readonly pdiDate = [
      (value: string) => {
        const momentDate = moment(value, 'DD/MM/YYYY');
        const today = moment();
        return momentDate.isAfter(today.add(14, 'day'))
          || 'Prazo de pelo menos 15 dias'
      },
    ]

    readonly invalidNextCountDate = [
      (value: string) => {
        const momentNextCountDate = moment(value, 'DD/MM/YYYY').endOf('month');
        if (momentNextCountDate.isSameOrBefore(moment().endOf('month'))) { return 'Data inválida. Favor verificar a data do próximo acordo.' }
        return true;
      },
    ]

    readonly requestReason = [
      (value: string) => {
        if (value === 'SYSTEM') {
          return 'Campo obrigatório.'
        }
        return (!!value) || 'Campo obrigatório.'
      },
    ]

    readonly emptyArray = [
      (value: string[]) => {
        if (value.length === 0) {
          return 'Campo obrigatório.'
        }
        return true
      },
    ]

    readonly nullOrUndefined = [(v: any) => !!(v && v.id) || 'Campo obrigatório.'];

    readonly emptyMoney = [
      (value: string) => (!!value) || 'Campo obrigatório.',
    ]

    readonly betweenZeroAndThirty = [
      (value: string) => !!value || 'Campo obrigatório.',
      (value: string) => (!!value && parseFloat(value) >= 0 && parseFloat(value) < 30.1) || 'Valor deve ficar entre 0 e 30.',
    ]

    readonly onlyNumeric = [
      (value: string) => (/^[0-9]+$/.test(value)) || 'Por favor, digite apenas valores numéricos.',
    ];

    readonly emptyBoolean = [
      (value: boolean) => (value === null || value === undefined ? 'Campo obrigatório.' : true),
    ];
}
