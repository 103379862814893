












































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import Attendance from '@/domain/attendance/entity/attendance.entity';
import { VbSnackbarService } from 'bag-of-holding-library';
import AttendanceService from '@/domain/attendance/service/attendance.service';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import EmployeeAttendanceDTO from '@/domain/attendance/entity/employee-attendance-dto';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';

@Component({
  components: {
    LabelSlot,
    CardTitle,
    ModalWarning,
  },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class AttendanceRegularComponent extends Vue {
  @Prop() attendance!: Attendance;

  @Prop() attendanceId!: string;

  @Prop() hasDeleteAttendance!: boolean;

  @Prop() updateAttendance!: boolean;

  @Prop() form!: any;

  attendanceAux: Attendance = Attendance.of({});

  isAdministrator: boolean = false;

  @Prop() disableSave!: boolean;

  private loading = false;

  private warningModal: boolean = false;

  get saveText() {
    return this.attendance.equals(this.attendanceAux)
      ? 'Confirmar' : 'Salvar';
  }

  close() {
    this.$router.push({
      path: '/attendance',
    })
  }

  private closeWarningModal(): void {
    this.warningModal = false;
  }

  private openWarningModal(): void {
    this.warningModal = true;
  }

  private deleteAttendance() {
    this.closeWarningModal();
    this.loading = true;

    AttendanceService.deleteAttendance(this.attendance.id).then(() => {
      VbSnackbarService.showSuccess('Atendimento excluído com sucesso!');
    }).catch((err: any) => {
      VbSnackbarService.handleHttpError(err)
    }).finally(() => {
      this.loading = false;
      this.close()
    });
  }

  async save() {
    if (!this.form.validate()) return;
    this.loading = true;
    const responsible = await EmployeeService.getEmployeeById(this.attendance.responsible.id)
    this.attendance.responsible = EmployeeAttendanceDTO.of(responsible);
    const request = Attendance.of(this.attendance);

    const remuneration = this.remunerationFormated(request.currentRemuneration);
    request.currentRemuneration = remuneration;

    try {
      await AttendanceService.saveAttendance(this.attendanceId, request);
      VbSnackbarService.showSuccess('Dados salvos com sucesso!');
      this.close();
    } catch (err) {
      VbSnackbarService.handleHttpError(err);
    } finally {
      this.loading = false;
      this.attendanceAux = Attendance.of(this.attendance);
    }
  }

  private remunerationFormated(currentRemuneration: string) {
    if (currentRemuneration === null) return '';
    return Attendance.formatValue(currentRemuneration);
  }
}

