import axios from 'axios';
import CareerUpdate from '../entity/career-update.entity';
import CareerUpdateHistory from '../entity/career-update-history.entity';
import UpdateAtribute from '../entity/update-atribute.entity';
import CurrentCareerUpdate from '../entity/current-career-update.entity';
import CareerUpdatePersist from '../entity/career-update-persist.entity';

export default class CareerService {
  public static getRoles(): Promise<any[]> {
    return axios.get('/api/roles');
  }

  public static getWorkplace() {
    return axios.get('/api/workplaces');
  }

  public static getSeniority(): Promise<any[]> {
    return axios.get('/api/seniorities');
  }

  public static getOperationModality(): Promise<any[]> {
    return axios.get('/api/operation-modalities');
  }

  public static getBusinessUnit(): Promise<UpdateAtribute[]> {
    return axios.get('/api/business-units')
      .then((response: any) => UpdateAtribute.ofList(response.data));
  }

  public static getResultCenter(businessUnitId): Promise<UpdateAtribute[]> {
    return axios.get(`/api/result-centers?businessUnitId=${businessUnitId}`)
      .then((response: any) => UpdateAtribute.ofList(response.data));
  }

  public static saveCareer(id: string, update: CareerUpdatePersist): Promise<CareerUpdate> {
    return axios.post(`/api/employees/${id}/career-updates`, update)
      .then(({ data }: any) => CareerUpdate.of(data));
  }

  public static updateCareer(employeeId: string,
    id: string, update: any): Promise<CareerUpdate> {
    return axios.put(`/api/employees/${employeeId}/career-updates/${id}`, update)
      .then((response: any) => CareerUpdate.of(response.data));
  }

  public static getCareerUpdateHistory(id: string): Promise<CareerUpdateHistory[]> {
    return axios.get(`/api/employees/${id}/career-updates/history`)
      .then((response: any) => CareerUpdateHistory.ofList(response.data));
  }

  public static deleteCareer(employeeId: string, id: string): Promise<CareerUpdate> {
    return axios.delete(`/api/employees/${employeeId}/career-updates/${id}`);
  }

  public static getCurrentCareerUpdate(id: string): Promise<CurrentCareerUpdate> {
    return axios.get(`/api/employees/${id}/career-updates/current`)
      .then((response: any) => CurrentCareerUpdate.of(response.data));
  }

  public static getResultCentersByName(name: string): Promise<UpdateAtribute[]> {
    return axios.get(`/api/result-centers/${name}`)
      .then((response: any) => UpdateAtribute.ofList(response.data));
  }
}
