













































































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { VbLoadingService, VbSnackbarService } from 'bag-of-holding-library';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import InternalMovementService from '@/domain/internal-movement/service/internal-movement.service';
import EmployeeTalentLevelService from '@/domain/employees/service/employee-talent-level.service';
import VacationService from '@/domain/vacation/service/vacation.service';
import Rules from '@/shared/rules/rules';
import ModalComponent from '@/shared/modal/modal.component.vue';
import EmployeeTalentLevel from '@/domain/employees/entity/employee-talent-level.entity';
import ModalOptions from '@/shared/modal/modal-options.entity';
import InternalMovementRequest from '@/domain/internal-movement/entity/internal-movement-request.entity';


@Component({
  components: {
    LabelSlot,
    ModalComponent,
    DatePickerField,
  },
})

export default class InternalMovementModal extends Vue {
  @Prop() loadingSaveButton!: boolean;

  @Prop() showModal!: boolean;

  @Prop() employeeId!: string;

  key: string = new Date().toISOString();

  movement: InternalMovementRequest = InternalMovementRequest.of({});

  rules: Rules = new Rules();

  talentLevel: EmployeeTalentLevel = new EmployeeTalentLevel();

  vacationBalance: number | null = null;

  @Watch('showModal')
  onShowModalChange() {
    if (this.showModal) {
      this.getTalentLevel();
      this.getVacationBalance();
    }
  }

  get optionsHandled(): ModalOptions {
    return {
      title: 'Solicitação de Movimentação Interna',
      titleSize: '20px',
      description: '',
      showOk: true,
      disabledOk: false,
      labelOk: 'Enviar Solicitação',
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 700,
      loadingOk: this.loadingSaveButton,
    };
  }

  saveNewInternalMovement() {
    const { form }: any = this.$refs;
    const isValid = form.validate();
    if (!isValid) return;
    this.loadingSaveButton = true;
    InternalMovementService.requestNewInternalMovement(this.employeeId, this.movement.targetDate,
      this.movement.areasOfInterest, this.movement.bankHoursBalance)
      .then(() => {
        VbSnackbarService.showSuccess('Movimentação interna solicitada com sucesso');
        this.close();
      })
      .catch(err => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        VbLoadingService.hide();
        this.loadingSaveButton = false;
      });
  }

  async getTalentLevel() {
    if (this.talentLevel.name) return;
    EmployeeTalentLevelService.getTalentLevelByEmployeeId(this.employeeId)
      .then((response) => {
        this.talentLevel = response;
      })
  }

  async getVacationBalance() {
    if (this.vacationBalance !== null && this.vacationBalance >= 0) return;
    VacationService.getVacationBalance(this.employeeId)
      .then((response) => {
        this.vacationBalance = response;
      });
  }

  close() {
    this.$emit('close');
  }

  updateDateLimit(newDate: string) {
    this.movement.targetDate = newDate;
  }
}

