



























































import {
  Vue, Prop, Component,
} from 'vue-property-decorator';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import { VbTooltipSlot } from 'bag-of-holding-library';
import ActivityDetailed from '@/domain/schedule/activitie/activity-detailed.entity';

@Component({
  components: {
    'modal-warning': ModalWarning,
    VbTooltipSlot,
  },
})
export default class ManageActivityListItemActionButtons extends Vue {
  @Prop() currentStatus!: string;

  @Prop() disabled!: boolean;

  @Prop() deleteLoading!: boolean;

  @Prop({
    required: true,
  })
  activity!: ActivityDetailed;

  @Prop({
    type: Boolean,
    default: false,
  })
  isUpdateActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isDeleteActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isUpdateActivityStatus!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isAdm!: boolean;

  warningModal: boolean = false;

  statusReferenceForWarningModal: string = '';

  openWarningModal(statusReferenceForWarningModal: string): void {
    this.warningModal = true;
    this.statusReferenceForWarningModal = statusReferenceForWarningModal;
  }

  closeWarningModal(): void {
    this.warningModal = false;
  }

  openUpdateStatusModal() {
    this.$emit('updateActivityStatus')
  }

  confirm(): void {
    if (this.statusReferenceForWarningModal === 'Aprovada') this.$emit('approveActivity');
    if (this.statusReferenceForWarningModal === 'Recusada') this.$emit('rejectActivity');
    if (this.statusReferenceForWarningModal === 'Deletada') this.$emit('deletedActivity');
    this.closeWarningModal();
  }

  get showReject() {
    return this.isUpdateActivityStatus && (!this.currentStatus || this.currentStatus === 'Aprovada' || this.currentStatus === 'Atrasada');
  }

  get showAprove() {
    return this.isAdm && this.isUpdateActivityStatus && (!this.currentStatus || this.currentStatus === 'Recusada' || this.currentStatus === 'Atrasada');
  }

  get showDelete() {
    return !this.currentStatus || this.currentStatus === 'Recusada' || this.currentStatus === 'Atrasada'
  }

  approveTooltip = this.disabled ? 'Favor preencher campo Resultado' : 'Aprovar';

  rejectTooltip = this.disabled ? 'Favor preencher campo Resultado' : 'Rejeitar';
}
