


























import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import Rules from '../rules/rules';
import LabelSlot from '../slots/label-slot.component.vue';
import debounce from '../utils/debounce';

@Component({
  components: {
    LabelSlot,
  },
})
export default class EmployeeSelect extends Vue {
  @Prop({
    type: String,
    default: 'Colaborador',
  }) label!: string;

  @Prop({
    default: () => null,
  }) initialValue!: EmployeeSummary;

  @Prop() rules!: Rules;

  @Prop({
    type: Boolean,
    default: false,
  }) returnObject!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) clearable!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly!: boolean;

  employeeSelected: EmployeeSummary | null = null;

  searchInput: string = '';

  employees: EmployeeSummary[] = [];

  lastFilterEmployee: string = '';

  loading: boolean = false;

  @Watch('initialValue', { immediate: true })
  onInitialValueChange() {
    if (this.initialValue) {
      this.employeeSelected = EmployeeSummary.of(this.initialValue);
      this.employees.push(this.initialValue);
    }
  }

  findEmployees() {
    if (this.employeeSelected) return;
    if ((this.lastFilterEmployee !== this.searchInput) && this.searchInput) {
      this.lastFilterEmployee = this.searchInput;
      if (this.searchInput.length > 3) {
        const employeeFinded = this.filterExistingEmployees();
        if (employeeFinded) return;
        debounce.debounce(() => this.getEmployee(this.searchInput))
      }
    }
  }

  filterExistingEmployees(): EmployeeSummary | undefined {
    return this.employees
      .find(employee => employee.name.toLowerCase().includes(this.searchInput.toLowerCase()));
  }

  getEmployee(name: string) {
    this.loading = true;
    EmployeeService.findEmployeesByName(name)
      .then((response) => {
        this.employees.push(...response);
      })
      .finally(() => this.loading = false);
  }

  update(employeeSelected: EmployeeSummary | null) {
    this.$emit('update', employeeSelected);
  }
}
