





























































































































import { Vue, Component } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import FilterButtonComponent from '@/shared/buttons/filter-button.component.vue';
import debounce from '@/shared/utils/debounce';
import InternalMovementFilter from '@/domain/internal-movement/entity/internal-movement-filter.entity';
import internalMovementStatus from './internalMovementStatus.json';

@Component({
  components: {
    LabelSlot,
    DatePickerField,
    FilterButtonComponent,
  },
})
export default class InternalMovementFilterComponent extends Vue {
  showFilter: boolean = false;

  filter: InternalMovementFilter = new InternalMovementFilter();

  types = internalMovementStatus;

  clearFilter(filter: string) {
    this.filter[filter] = '';
    this.find();
  }

  setDateFilterField(field: string, value: string) {
    this.filter[field] = value;
    this.find();
  }

  find() {
    this.$emit('find', this.filter);
  }

  findDebounce(value: string) {
    if (value.length !== 0 && value.length < 3) return;
    debounce.debounce(() => this.find(), 700);
  }
}
