

















































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import VacationPayslip from '@/domain/employees/entity/vacation-payslip.entity'
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import VacationPayslipService from '@/domain/employees/service/vacation-payslip.service';
import DateHandler from '@/shared/utils/date-format';

@Component({
  components: {
    LabelSlot, CardTitle, VbTooltipSlot, MonthYearSelect,
  },
})

export default class VacationPayslipComponent extends Vue {
  rules: Rules = new Rules();

  loading = false;

  competence = '';

  vacation: VacationPayslip = new VacationPayslip({});

  save() {
    this.vacation.date = DateHandler.localToTimestamp(`01/${this.competence}`);
    this.loading = true;
    VacationPayslipService.save(this.vacation)
      .then((resp: any) => {
        VbSnackbarService.showSuccess('Holerite enviado com sucesso. Aguarde o processamento.');
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => this.clearInput());
  }

  clearInput() {
    this.competence = '';
    this.vacation.file = '';
    this.loading = false
  }
}

