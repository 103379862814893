import moment from 'moment';
import DateFormat from '@/shared/utils/date-format';
import CareerUpdate from '../employees/entity/career-update.entity';
import SkillType from '../employees/entity/skill-type.entity';
import EmployeeSummary from '../schedule/employee/employee-summary.entity';
import InternalMovementKnowledge from './entity/internal-movement-knowledge.entity';
import { InternalMovementStatus } from './entity/internal-movement-status.enum';
import InternalMovementUpdateRequest from './entity/internal-movement-update-request.entity';
import InternalMovementUpdateResponse from './entity/internal-movement-update-response.entity';

export default class InternalMovement {
  id: string;

  employeeId: string;

  employeeName: string;

  employeeUsername: string;

  currentCareerUpdate: CareerUpdate;

  admissionDate: string;

  talentLevel: string;

  vacationBalance: number;

  bankHoursBalance: number;

  status: string;

  targetDate: string;

  areasOfInterest: string;

  responsibleId: string;

  responsibleName: string;

  isRiskAttendance: boolean;

  knowledgeList: InternalMovementKnowledge[];

  startedDate: string;

  concludedDate: string;

  createdByName: string;

  lastUpdateDate: string;

  lastUpdateByName: string;

  cancelledObservation: string;

  cancelledDate: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.employeeId = obj.employeeId || '';
    this.employeeName = obj.employeeName || '';
    this.employeeUsername = obj.employeeUsername || '';
    this.currentCareerUpdate = new CareerUpdate(obj.currentCareerUpdate || {});
    this.admissionDate = obj.admissionDate || '';
    this.talentLevel = obj.talentLevel || '';
    this.vacationBalance = obj.vacationBalance;
    this.bankHoursBalance = obj.bankHoursBalance;
    this.status = obj.status || '';
    this.targetDate = obj.targetDate || '';
    this.areasOfInterest = obj.areasOfInterest || '';
    this.responsibleId = obj.responsibleId || '';
    this.responsibleName = obj.responsibleName || '';
    this.isRiskAttendance = obj.isRiskAttendance;
    this.knowledgeList = obj.knowledgeList
      ? obj.knowledgeList.map(it => new InternalMovementKnowledge(it)) : [];
    this.startedDate = obj.startedDate || '';
    this.concludedDate = obj.concludedDate || '';
    this.createdByName = obj.createdByName || '';
    this.lastUpdateDate = obj.lastUpdateDate || '';
    this.lastUpdateByName = obj.lastUpdateByName || '';
    this.cancelledObservation = obj.cancelledObservation || '';
    this.cancelledDate = obj.cancelledDate || '';
  }

  get admissionDateFormatted() {
    return this.admissionDate ? DateFormat.standardDate(this.admissionDate) : '';
  }

  get concludedDateFormatted() {
    return this.concludedDate ? moment(this.concludedDate).format('DD/MM/YYYY') : 'Não concluído';
  }

  get calculateTimeInCompany() {
    if (!this.admissionDate) return '';
    const admissionDate = moment(this.admissionDate);
    const today = moment();
    const diffYears = today.diff(admissionDate, 'years');
    if (diffYears > 0) return `${diffYears} ano${diffYears > 1 ? 's' : ''}`;
    const diffMonths = today.diff(admissionDate, 'months');
    if (diffMonths > 0) return `${diffMonths} ${diffMonths > 1 ? 'meses' : 'mês'}`;
    const diffDays = today.diff(admissionDate, 'days');
    return `${diffDays} dia${diffDays > 1 ? 's' : ''}`;
  }

  get hardSkills() {
    return this.knowledgeList.filter(it => it.type === SkillType.HARD_SKILLS);
  }

  get frameworksOrMethodologies() {
    return this.knowledgeList.filter(it => it.type === SkillType.FRAMEWORKS_OR_METHODOLOGIES);
  }

  get businessExperience() {
    return this.knowledgeList.filter(it => it.type === SkillType.BUSINESS_EXPERIENCE);
  }

  get certifiations() {
    return this.knowledgeList.filter(it => it.type === SkillType.CERTIFICATIONS);
  }

  get responsible() {
    if (!this.responsibleId) return null;
    return EmployeeSummary.of({
      id: this.responsibleId,
      name: this.responsibleName,
    });
  }

  get isConcluded() {
    return this.status === InternalMovementStatus.CONCLUDED;
  }

  get isNotStarted() {
    return this.status === InternalMovementStatus.NOT_STARTED;
  }

  get isCancelled() {
    return this.status === InternalMovementStatus.CANCELLED;
  }

  updateResponsable(employee: EmployeeSummary) {
    if (!employee) return;
    this.responsibleId = employee.id;
    this.responsibleName = employee.name;
  }

  toUpdate(): InternalMovementUpdateRequest {
    return new InternalMovementUpdateRequest({
      bankHoursBalance: this.bankHoursBalance,
      targetDate: this.targetDate,
      responsibleId: this.responsibleId,
      areasOfInterest: this.areasOfInterest,
      isRiskAttendance: this.isRiskAttendance,
    })
  }

  update(response: InternalMovementUpdateResponse) {
    this.bankHoursBalance = response.bankHoursBalance;
    this.targetDate = response.targetDate;
    this.responsibleId = response.responsibleId;
    this.responsibleName = response.responsibleName;
    this.areasOfInterest = response.areasOfInterest;
    this.isRiskAttendance = response.isRiskAttendance;
  }
}
