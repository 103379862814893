import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import FilterUtils from '@/shared/utils/filters';

export default class ResignationStaffRequest {
  id: any = null;

  email: string;

  resultCenters: any = [];

  resignationTypes: string[];

  constructor(obj: any) {
    if (obj.id !== '') {
      this.id = obj.id;
    }
    this.email = obj.email || '';
    this.resignationTypes = obj.resignationTypes || [];
    if (obj.hasResultCenter) {
      this.resultCenters = obj.resultCenters.map(el => el.id);
    }
  }

  public static of(obj: any): ResignationStaffRequest {
    return new ResignationStaffRequest(obj || {});
  }

  public static ofList(obj: any[]): ResignationStaffRequest[] {
    return obj.map(this.of);
  }
}
